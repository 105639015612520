import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { Navigate } from 'react-router-dom';

import CustomPanel from '../../../common/CustomPanel';
import OperatorAndPasswordModal from './OperatorAndPasswordModal';
import OperatorTable from './OperatorTable';

import { isEmpty } from '../../../../misc/utils';
import { TOASTIFY_SEVERITY } from '../../../../misc/const';
import { translateComp } from '../../../../i18n/utils';

import '../../../../css/operatorform.css';
import Progressbar from '../../../common/Progressbar';

/**
 * Klasse fuer einen Dashboard von Operators
 *
 * @export
 * @class OperatorDashboard
 * @extends {Component}
 */

export default function OperatorDashboard({
    requestOperatorData,
    requestLicenseKey,
    sendMessage,
    deleteOperator,
    changeOperatorPassword,
    updateOperator,
    domainState: {
        operators: { items = [], isFetching },
        license: { data = {}, isFetchingLicense },
    },
    userState: {
        authentication: { user: authUser },
    },

    addOperator,
}) {
    const [numberOfCurrentOperators, setNumberOfCurrentOperators] = useState(0);
    const [numberOfPossibleOperators, setNumberOfPossibleOperators] = useState(0);
    const [operatorItems, setOperatorItems] = useState(items);

    useEffect(() => {
        requestOperatorData();
        requestLicenseKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNumberOfCurrentOperators(items.length);
        setOperatorItems(items);
    }, [items]);

    useEffect(() => {
        setNumberOfCurrentOperators(data.nowOperators);
        setNumberOfPossibleOperators(data.maxOperators);
    }, [data]);

    if (!authUser) {
        return <Navigate to='/login' />;
    }
    
    const deleteOperatorHandler = operator => {
        if (!isEmpty(operator._id)) {
            if (operator.active) {
                sendMessage({
                    text: 'messages.operatorActive',
                    severity: TOASTIFY_SEVERITY.WARNING,
                });
            } else {
                deleteOperator(operator);
            }
        }
    };

    const renderAddButton = () => {
        const addButtonDisabled = !authUser.operator.create || numberOfCurrentOperators >= numberOfPossibleOperators;

        return (
            <OperatorAndPasswordModal
                disabled={addButtonDisabled}
                icon='plus'
                text={translateComp('operator.add', 'Benutzer Hinzufügen')}
                buttonText={translateComp('common.add', 'Hinzufügen')}
                submitCallback={addOperator}
            />
        );
    };

    return (
        <CustomPanel
            title={translateComp('common.operators', 'Operators')}
            loader={isFetching || isFetchingLicense}
            rightContent={() => renderAddButton(authUser)}
            description={translateComp('operator.description', 'Benutzer zur Verwendung der App auf dem mobilen Gerät')}
        >
            <Form>
                {!isEmpty(data) && (
                    <Progressbar
                        id='operatorProgress'
                        label={translateComp('license.operators', 'Operators')}
                        now={numberOfCurrentOperators}
                        max={numberOfPossibleOperators}
                    />
                )}
                <OperatorTable
                    operators={operatorItems}
                    rights={authUser.operator}
                    submitOperator={updateOperator}
                    deleteCallback={deleteOperatorHandler}
                    passwordCallback={changeOperatorPassword}
                />
            </Form>
        </CustomPanel>
    );
}
