import React from 'react';
import { Image } from 'semantic-ui-react';

import redLight from '../../images/red_light.png';
import greenLight from '../../images/green_light.png';

export default function ActiveImage(props) {
    const { active } = props || {};

    if (active) {
        return <Image src={greenLight} className='activeImage' alt={active.toString()} width='35' height='35' />;
    }
    return <Image src={redLight} className='activeImage' alt={active.toString()} width='35' height='35' />;
}
