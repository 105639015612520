import { get, post, del } from '../API';
import { ALL_USERS, CREATE_USER, USER } from '../paths';

export function getUsers() {
    return get(ALL_USERS);
}

export function postCreateUser(user) {
    return post(CREATE_USER, user);
}

export function postUser(user) {
    return post(`${USER}/${user._id}`, user);
}

export function deleteUser(id) {
    return del(`${USER}/${id}`);
}
