import BaseElement from './BaseElement';

type BaseValidationElementOptions = {
    show?: boolean,
    title: string,
    required?: boolean,
    accessor: string,
    validationMethod?: (Object, any, boolean) => boolean,
};

/**
 * Hilfsfunktion um zu pruefen ob das Objekt das Element hat, um dieses dann zurueckzugeben
 * Ansonsten wird der fallback gegeben
 *
 * @export
 * @param {string} name Name des Feldes im Objekt
 * @param {Object} origin Objekt, das diese Feld enthalten kann
 * @param {*} fallback Wert, der zurueck gegeben wird falls nicht vorhanden
 * @returns
 */
export function setIfExits(name: string, origin: Object, fallback: any) {
    if (origin.hasOwnProperty(name) && typeof origin[name] !== 'undefined') {
        return origin[name];
    }

    return fallback;
}

/**
 * Basisklasse fuer Element auf dem Formular, die validiert werden koennen
 *
 * @export
 * @class BaseValidationElement
 * @extends {BaseElement}
 */
export default class BaseValidationElement extends BaseElement {
    show: boolean;

    validationState: 'success' | 'warning' | 'error' | null = null;

    title: string;

    required: boolean;

    accessor: string;

    validationMethod: ?(Object, any, boolean) => boolean = null;

    /**
     * Creates an instance of BaseValidationElement.
     * @param {string} id Id des Elements
     * @param {BaseValidationElementOptions} options Optionen dieses Feldes
     * @memberof BaseValidationElement
     */
    constructor(id: string, options: BaseValidationElementOptions) {
        super(id);

        this.show = setIfExits('show', options, true);
        this.title = options.title;
        this.required = setIfExits('required', options, false);
        this.accessor = options.accessor;
        this.validationMethod = options.validationMethod;
    }

    /**
     * Gibt an ob das Feld valide ist
     *
     * @param {Object} obj Objekt mit den Werten
     * @param {*} value Wert des Feldes
     * @param {boolean} newObj Ob es ein neues Objekt ist
     * @returns Ob es valide ist
     * @memberof BaseValidationElement
     */
    isValid() {
        return true;
    }
}
