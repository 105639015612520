import React from 'react';
import { Table } from 'semantic-ui-react';

import Toggle from '../../../common/form/components/Toggle';

export default function RightsTableRow({ rowTitle, rights, accessor, callback, enabledRightsObj }) {
    return (
        <Table.Row>
            <Table.Cell collapsing>{rowTitle}</Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Toggle
                    checked={rights.read}
                    onClick={() => callback(accessor, 'read', !rights.read)}
                    disabled={!enabledRightsObj.read}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Toggle
                    checked={rights.create}
                    onClick={() => callback(accessor, 'create', !rights.create)}
                    disabled={!enabledRightsObj.create}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Toggle
                    checked={rights.update}
                    onClick={() => callback(accessor, 'update', !rights.update)}
                    disabled={!enabledRightsObj.update}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Toggle
                    checked={rights.delete}
                    onClick={() => callback(accessor, 'delete', !rights.delete)}
                    disabled={!enabledRightsObj.delete}
                />
            </Table.Cell>
        </Table.Row>
    );
}
