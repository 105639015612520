import React from 'react';
import { Table } from 'semantic-ui-react';

import DeviceTableRow from './DeviceTableRow';

import { translateComp } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';

export default function DeviceTable({ rights, devices, submitDevice, deleteCallback, isValid }) {
    return (
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{translateComp('device.active', 'Active')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('device.deviceid', 'Device-ID')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('device.name', 'Name')}</Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.edit', 'Bearbeiten')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.delete', 'Löschen')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {!isEmpty(devices) &&
                    devices.map((device, index) => {
                        return (
                            <DeviceTableRow
                                key={index}
                                rights={rights}
                                device={device}
                                submitDevice={submitDevice}
                                deleteCallback={deleteCallback}
                                isValid={isValid}
                            />
                        );
                    })}

                {isEmpty(devices) && (
                    <Table.Row>
                        <Table.Cell colSpan='5'>{translateComp('table.noDataText', 'Currently no data')}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
}
