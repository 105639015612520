import React, { useEffect, useState } from 'react';

import AdvancedModal from '../../../common/AdvancedModal';
import UserForm from './UserForm';
import * as userModel from '../../../../models/user';
import { objectHasEmptyValues, isValidMailAddress } from '../../../../misc/utils';

export default function UserModal({ disabled, icon, text, buttonText, submitCallback, user = userModel.user }) {
    const [_user, setUser] = useState(user);
    const [isValidForm, setIsValidForm] = useState(true);
    const [shouldCloseModal, setCloseModal] = useState(false);
    useEffect(() => {}, [_user]);
    return (
        <AdvancedModal
            disabled={disabled}
            headerIcon={icon}
            headerText={text}
            buttonIcon={icon}
            buttonText={buttonText}
            open={!shouldCloseModal}
            isValidForm={isValidForm}
            submitCallback={() => {
                if (objectHasEmptyValues(_user) || !isValidMailAddress(_user.mail)) {
                    setIsValidForm(false);
                } else {
                    setIsValidForm(true);
                    setCloseModal(true);
                    submitCallback(_user);
                }
            }}
            cancelCallback={() => {
                setUser(user);
                setIsValidForm(true);
            }}
        >
            <UserForm
                onChange={(key, val) => {
                    setUser({ ..._user, [key]: val });
                }}
                user={_user}
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
            />
        </AdvancedModal>
    );
}
