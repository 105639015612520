/* eslint-disable class-methods-use-this */
import React, { useEffect } from 'react';
import { Table, Form } from 'semantic-ui-react';

import { Navigate } from 'react-router-dom';
import CustomPanel from '../../../common/CustomPanel';
import OperatorDeviceTableRow from './OperatorDeviceTableRow';

import { translateComp } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';

export default function OperatorDeviceInspector(props) {
    useEffect(() => {
        const { requestOperatorDeviceData } = props;
        requestOperatorDeviceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { token } = props;
    if (!token) {
        return <Navigate to='/login' />;
    }

    const {
        domainState: {
            operatorDevices: { items = [], isFetching },
        },
    } = props;

    return (
        <CustomPanel
            title={translateComp('common.operatorDevice', 'Aktive Benutzer und Geräte')}
            loader={isFetching}
            description={translateComp(
                'common.description',
                'Übersicht welche Benuzter aktuell mit welchem Gerät angemeldet sind',
            )}
        >
            <Form>
                <Table celled unstackable striped style={{ width: '600px' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>{translateComp('common.loggedInAt', 'Eingeloggt am')}</Table.HeaderCell>
                            <Table.HeaderCell>{translateComp('common.operator', 'Operator')}</Table.HeaderCell>
                            <Table.HeaderCell>{translateComp('common.device', 'Gerät')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {!isEmpty(items) &&
                            items.map((item, count) => {
                                const keyStr = 'odr_' + count;
                                return <OperatorDeviceTableRow key={keyStr+'odtr'} item={item} keyStr={keyStr} />;
                            })}
                        {isEmpty(items) && (
                            <Table.Row>
                                <Table.Cell colSpan='3'>{translateComp('table.noDataText', 'Currently no data')}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </Form>
        </CustomPanel>
    );
}
