import React from 'react';
import { Form, Select } from 'semantic-ui-react';

import { translateString } from '../../i18n/utils';
import { isEmpty, isFunction } from '../../misc/utils';

import '../../css/labelinputfield.css';

export default function LabelDropdownField({
    id,
    label,
    options,
    disabled = false,
    onChange,
    value,
    placeholder,
    hasError = false,
    style,
}) {
    return (
        <Form.Field
            id={id}
            control={Select}
            options={options}
            label={label}
            placeholder={isEmpty(placeholder) ? translateString('common.placeholder') : placeholder}
            disabled={disabled}
            error={hasError}
            onChange={(e, { value: newValue }) => {
                if (isFunction(onChange)) {
                    onChange(newValue);
                }
            }}
            value={value}
            style={style}
        />
    );
}
