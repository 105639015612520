import React from 'react';
import { Table } from 'semantic-ui-react';

import OperatorModal from './OperatorModal';
import OperatorPasswordModal from './OperatorPasswordModal';
import ActiveImage from '../../../common/ActiveImage';
import DeleteConfirmDialog from '../../../common/DeleteConfirmDialog';

import { translateComp, translateString } from '../../../../i18n/utils';

export default function OperatorTableRow({ rights, operator, submitOperator, deleteCallback, passwordCallback }) {
    const deletable = operator.active;

    const deleteCallbackHandler = () => {
        deleteCallback(operator);
    };

    const deleteMessage = translateString('messages.deleteOperateurMessage', {
        username: operator.username,
        forname: operator.forname,
        surname: operator.surname,
        mail: operator.mail,
    });

    return (
        <Table.Row>
            <Table.Cell>
                <ActiveImage active={operator.active} />
            </Table.Cell>
            <Table.Cell>{operator.username}</Table.Cell>
            <Table.Cell>{operator.forname}</Table.Cell>
            <Table.Cell>{operator.surname}</Table.Cell>
            <Table.Cell>{operator.mail}</Table.Cell>
            <Table.Cell>{operator.phone}</Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <OperatorModal
                    disabled={!rights.update}
                    icon='pencil'
                    text={translateComp('common.operator', 'Operators')}
                    submitCallback={submitOperator}
                    operator={operator}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <DeleteConfirmDialog
                    confirmCallback={deleteCallbackHandler}
                    header={translateString('operator.delete')}
                    content={deleteMessage}
                    cancelButtonText={translateString('common.abort')}
                    confirmButtonText={translateString('common.ok')}
                    disabled={!rights.delete || deletable}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <OperatorPasswordModal
                    disabled={!rights.update}
                    submitCallback={password => passwordCallback(operator, password)}
                    operatorName={operator.username}
                />
            </Table.Cell>
        </Table.Row>
    );
}
