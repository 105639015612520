import { get, post, del } from '../API';
import { ALL_MAPS, CHECK_MAPS, CREATE_MAPS, GET_LAYER, SETDEFAULT_MAPS, MAPS } from '../paths';

export function getMapsList() {
    return get(ALL_MAPS);
}

export function postCreateMaps(maps) {
    return post(CREATE_MAPS, maps);
}


export function getLayer(capabilitiesurl) {
    return post(GET_LAYER, capabilitiesurl);
}

export function setDefaultMaps(maps) {
    return post(`${MAPS}/${maps._id}/${SETDEFAULT_MAPS}`, maps);
}

export function postMaps(maps) {
    return post(`${MAPS}/${maps._id}`, maps);
}

export function deleteMaps(id) {
    return del(`${MAPS}/${id}`);
}

export function postCheckMaps(maps) {
    return post(CHECK_MAPS, maps);
}