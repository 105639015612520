import React from 'react';

/**
 * Gibt eine Hinweisbox zurueck
 *
 * @export
 * @param {*} { show } boolean, sichtbar=true oder nicht
 * @param {*} { title } Uberschrift
 * @param {*} { message } Hinweistext
 * @returns
 */
export default function HintBox({ show, title, message }) {
    if (!show) return null;
    return (
        <div className='ui info message'>
            <div className='header'>{title}</div>
            <ul className='list'>
                <li>{message}</li>
            </ul>
        </div>
    );
}
