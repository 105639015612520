import { get } from '../API';
import { LICENSE_DATA, LICENSE_KEY } from '../paths';

export function getLicenseData() {
    return get(LICENSE_DATA);
}

export function getLicenseKey() {
    return get(LICENSE_KEY);
}
