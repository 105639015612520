/* eslint-disable import/prefer-default-export */
import { defaultRights } from './defaultRights';

export const user = {
    forname: '',
    surname: '',
    mail: '',
    phone: '',
    active: true,
    user: { ...defaultRights },
    customer: { ...defaultRights },
    operator: { ...defaultRights },
    device: { ...defaultRights },
};
