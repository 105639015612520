export const disabledUser = {
    user: {
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    operator: {
        create: false,
        read: false,
        update: false,
        delete: false,
    },
    device: {
        create: false,
        read: false,
        update: false,
        delete: false,
    },
};