import React from 'react';
import { Progress, Form } from 'semantic-ui-react';

/**
 * Komponente fuer Input
 * @export
 * @param {*} props
 * @returns
 */
export default function Progressbar({ label, max = 100, now = 0, progress = 'ratio' }) {
    let ratio = 100;

    if (max !== 0) {
        ratio = (now / max) * 100;
    }

    return (
        <Form.Field
            control={Progress}
            label={label}
            success={ratio < 70}
            warning={ratio >= 70 && ratio < 100}
            color={ratio === 100 ? 'red' : undefined}
            value={now}
            total={max}
            progress={progress}
        />
    );
}
