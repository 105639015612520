// @flow
import React, { useState, useEffect } from 'react';
import { Form, FormRadio } from 'semantic-ui-react';
import Validating from '../../../common/Validating';
import LabelInputField from '../../../common/LabelInputField';
import LabelTextAreaField from '../../../common/LabelTextAreaField';
import MapsLayers from './MapsLayers';
import { translateComp, translateString } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';
import { getLayer } from '../../../../actions/domainActions/mapsActions';
import {COLOR_SET} from '../../../../misc/const';
import '../../../../css/form.css';

export default function MapsForm({  onChange, maps, isValidForm, setIsValidForm,  checkMaps}) {
    const [appUrl, setAppUrl] = useState(maps.url || '');
    const [tilesurl, setTilesUrl]= useState(maps.tilesurl || 'https://tile.openstreetmap.de/');
    const [capabilitiesurl, setCapabilitiesurl] = useState(maps.capabilitiesurl || 'https://sgx.geodatenzentrum.de/wms_basemapde?SERVICE=WMS&REQUEST=GetCapabilities&SERVICE=WMS&VERSION=1.3.0');
    const [layers, setLayers] = useState([]);
    const [selLayers, setSelLayers] = useState(maps.layers); //KEYS der Layer Selektion
    const [isFetchingLayers, setIsFetchingLayers] = useState(false);
    const [version, setVersion] = useState('');

    const [loader, setLoader] = useState(false);
    const [errorInName, setErrorInName] = useState(false);
    const [errorInUrl, setErrorInUrl] = useState(false);
    const [isWms, setIsWms] = useState(maps.type);
    const [errorInUrlMessage, setErrorInUrlMessage] = useState('Dummy');
    const [errorInCapabilitiesUrl, setErrorInCapabilitiesUrl] = useState(false);
    const [errorInTilesUrl, setErrorInTilesUrl] = useState(false);
    const [errorInCapabilitiesUrlMessage, setErrorInCapabilitiesUrlMessage] = useState('Dummy');

    const [checkResult] = useState(); //TODO setCheckResult

    const typeOptions = [
        { key: 'wms', text: 'WMS', value: 'wms' },
        { key: 'tile', text: 'Tile', value: 'tile' },
    ]
    const typeValue = isWms? 'wms' : 'tile';

    const handleTypeChange = (e, { value }) => {
        setIsWms(!isWms);
        setErrorInTilesUrl(false); // TODO
    }

    const validNameHandler = () => {
        setErrorInName(isEmpty(maps.title));
    };

    const calculateUrlHandler = () => {
        if(isWms){
                    // Getestet mit:
        // Geodatenzentrum: https://sgx.geodatenzentrum.de/wms_basemapde?SERVICE=WMS&REQUEST=GetCapabilities&SERVICE=WMS&VERSION=1.3.0
        // Wolfsburg:  https://gis.stadt.wolfsburg.de/cgi-bin/mapserv?map=/gds/map/alkis_web/alkis.map&REQUEST=GetCapabilities&SERVICE=WMS&VERSION=1.3.0
        //          -> https://gis.stadt.wolfsburg.de/cgi-bin/mapserv?REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=TatsaechlicheNutzungFlaeche%2CBauwerkeFlaeche%2CGebaeudeFlaeche%2Ctexte_p1%2Ctexte_p2%2Ctexte_l%2Chnr1%2Chnr2%2Chnr3&TILED=true&CRS=EPSG%3A3857&map=%2Fgds%2Fmap%2Falkis_web%2Falkis.map&WIDTH=256&HEIGHT=256&BBOX={bbox-epsg-3857}
        // Norderstedt: https://geoservice.norderstedt.de/geoserver/bpl/bplan_clip/wms?SERVICE=WMS&request=GetCapabilities
            const trimmesCapabilitiesUrl = capabilitiesurl.trim();
            const startPoint = trimmesCapabilitiesUrl.indexOf('?')+1;
            const paramsStr = trimmesCapabilitiesUrl.slice(startPoint, trimmesCapabilitiesUrl.length);

            const startStr = trimmesCapabilitiesUrl.slice(0, startPoint);
            const REQUEST = 'REQUEST=GetMap';
            const BBOX =  '&BBOX={bbox-epsg-3857}';
            const FORMAT = '&FORMAT=image/png';
            const LAYERS = '&LAYERS=' + selLayers.join(',');
            const STYLES = '&STYLES=';
            const TILED = ''; //'&TILED=true'; // TODO immer true??
            const CRS = '&CRS=EPSG:3857';
            const WIDTH = '&WIDTH=256';
            const HEIGHT = '&HEIGHT=256';
            const TRANSPARENT = '&TRANSPARENT=true';
            
            const SERVICE = '&SERVICE=WMS';

            const paramArr = paramsStr.split('&');
            const map = paramArr.find((param)=>{
                return param.toLowerCase().startsWith('map=');
            });
            const MAP = map? '&'+map : '';

            const vers = paramArr.find((param)=>{
                return param.toLowerCase().startsWith('version=')
            });
            const VERSION = vers? '&'+vers : '&VERSION='+version;

            setAppUrl(startStr + REQUEST + MAP  + SERVICE + VERSION + FORMAT + TILED + CRS + STYLES + TRANSPARENT + LAYERS + WIDTH + HEIGHT + BBOX);
            
        }else{
            setAppUrl(tilesurl + '{z}/{x}/{y}.png');
        }
     };

    const selectLayerHandler = (layerSelectionInfo) => {
        if(layerSelectionInfo.checked){
            setSelLayers([...selLayers, layerSelectionInfo.name]);
        }else{
            const newArr = selLayers.filter((layerInfo)=>{
                return (layerInfo !== layerSelectionInfo.name);
            })
            setSelLayers(newArr);
        }
    }

    useEffect(()=>{
        // Aktualisiert die Url damit diese in der vorgeschalteten Seite den Status aktualisieren kann ob speichern enabled wird
        onChange('url', appUrl);
        // eslint-disable-next-line
    }, [appUrl]);

    useEffect(()=>{
        // Aktualisiert die Layer damit diese mit gespeichert werden koennen
        onChange('layers', selLayers);
        // eslint-disable-next-line
    }, [selLayers]);

    const getLayerHandler = async() => {
        setIsFetchingLayers(true);
        const foundLayers = await getLayer({'capabilitiesurl': capabilitiesurl}).catch(err=>{
            setErrorInCapabilitiesUrl(err);
            setErrorInCapabilitiesUrlMessage('');
            setIsFetchingLayers(false);
        });
        setLayers(foundLayers.layers);
        setVersion(foundLayers.version);
    }

    const calculateButtonText = 'Url generieren';
    const layerButtonText = 'Layer anzeigen';

    useEffect(()=>{
        setIsFetchingLayers(false);
    }, [layers]);

    useEffect(() => {
       // setCalculatedIsValidForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorInName, errorInUrl]);

    useEffect(()=> {
        // ToDo hier gab es mal eine Validierung um zu prüfen ob es sich um einen Validen Capabilities Url handelt, ist ggf. überflüssig geworden weil das nun an den zurückgegebenen Layern erkannt wird
        validNameHandler();
        setLoader(false);
        if(checkResult === true){
            setErrorInUrl(false);
            setErrorInUrlMessage();
        }
        else if(checkResult?.name === 'AxiosError'){

            const err = checkResult.response.data;
            if(!isEmpty(err.message)){           
                setErrorInUrl(true);
                switch(err.message){
                    case "No URL":
                        setErrorInUrlMessage(translateString('maps.messages.mandatory_url'));
                        break;
                    case "URL not valid":
                        setErrorInUrlMessage(translateString('maps.messages.url_not_valid'));
                        break;
                    case "Url ist nor unique!":
                            setErrorInUrlMessage(translateString('maps.messages.url_not_unique'));
                            break;
                    case "URL not reachable":
                        setErrorInUrlMessage(translateString('maps.messages.url_notReachable'));
                        break;
                    case "No XML response":
                        setErrorInUrlMessage(translateString('maps.messages.response_no_xml'));
                        break;
                    case "No WMS Layers found":
                        setErrorInUrlMessage(translateString('maps.messages.response_no_layer'));
                        break;
                    default: {
                        setErrorInUrlMessage(translateString('maps.messages.unknown_err'));
                    }
                }            
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkResult]);
    
    return (
        <Form>
            <Validating show={loader} />
                <legend>{translateComp('maps.sections.step1', 'Step 1')}</legend>
                <LabelInputField
                    id='title'
                    type='text'
                    label={translateComp('maps.name', 'Name')}
                    onChange={val => onChange('title', val)}
                    onBlur={validNameHandler}
                    value={maps.title || ''}
                    style={{width: '200px'}}
                    hasError={errorInName}
                    required
                    errorMessage={translateString('maps.messages.mandatory_name')}
                />

                <legend>{translateComp('maps.sections.step2', 'Step 2')}</legend>

                <FormRadio
                    label={typeOptions[0].text}
                    value={typeOptions[0].value}
                    checked={typeValue === typeOptions[0].key}
                    onChange={handleTypeChange}
                    style={{ flexDirection:'row'}}
                    inline
                />
                <FormRadio
                    label={typeOptions[1].text}
                    value={typeOptions[1].value}
                    checked={typeValue === typeOptions[1].key}
                    onChange={handleTypeChange}
                    style={{ flexDirection:'row'}}
                    inline
                />       

                {!isWms &&
                <>
                    <legend>{translateComp('maps.sections.tiles.step3', 'Step 3')}</legend>
                    <LabelTextAreaField
                        id='tilesurl'
                        type='text'
                        label={translateComp('maps.tilesurl', 'URL')}
                        onChange={val => { 
                            setTilesUrl(val);
                            onChange('tilesurl', val);
                        }}                        
                        value={tilesurl}
                        hasError={errorInTilesUrl}
                        style={{width: '500px', height:'80px'}}
                        required={false}
                        disabled={false}
                        errorMessage={errorInCapabilitiesUrlMessage}
                    />
                </>
                }
            {isWms &&
                <>
                    <legend>{translateComp('maps.sections.wms.step3', 'Step 3')}</legend>
                    <LabelTextAreaField
                        id='capabilitiesurl'
                        type='text'
                        label={translateComp('maps.capabilitiesurl', 'URL')}
                        onChange={val => {
                            setCapabilitiesurl(val);
                            onChange('capabilitiesurl', val);
                        }}
                        value={capabilitiesurl}
                        hasError={errorInCapabilitiesUrl}
                        style={{width: '500px', height:'80px'}}
                        required={false}
                        disabled={false}
                        errorMessage={errorInCapabilitiesUrlMessage}
                    />

                    <legend>{translateComp('maps.sections.wms.step4', 'Step 4')}</legend>

                    <button
                        className='ui blue icon left labeled button'
                        disabled={capabilitiesurl.length<1}
                        style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, backgroundColor: COLOR_SET.PRIMARY }}
                        onClick={getLayerHandler}
                    ><i aria-hidden='true' className='map icon'></i>{layerButtonText}
                    </button>

                    <MapsLayers layers={layers} loader={isFetchingLayers} callback={selectLayerHandler} selLayers={selLayers}></MapsLayers>
                    <legend>{translateComp('maps.sections.wms.step5', 'Step 5')}</legend>

                    <button
                        className='ui blue icon web left labeled button'
                        disabled={selLayers.length<1}
                        style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, backgroundColor: COLOR_SET.PRIMARY }}
                        onClick={calculateUrlHandler}
                    ><i aria-hidden='true' className='globe icon'></i>{calculateButtonText}
                    </button> 
                </>
            }
            {!isWms &&
                <>
                    <legend>{translateComp('maps.sections.tiles.step4', 'Step 4')}</legend>
                    <button
                        className='ui blue icon web left labeled button'
                        disabled={tilesurl.length<1}
                        style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, backgroundColor: COLOR_SET.PRIMARY }}
                        onClick={calculateUrlHandler}
                    ><i aria-hidden='true' className='globe icon'></i>{calculateButtonText}
                    </button>    
                </>
            }
        
            <LabelTextAreaField
                    id='appUrl'
                    type='text'
                    label={translateComp('maps.url', 'URL')}
                    onChange={val => {
                        setAppUrl(val);
                        onChange('url', val);
                    }}
                    value={appUrl}
                    hasError={errorInUrl}
                    style={{width: '500px', height:'200px'}}
                    required
                    disabled={false}
                    errorMessage={errorInUrlMessage}
                />

        </Form>
    );
}
