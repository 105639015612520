import React, { useEffect, useState } from 'react';

import CustomPanel from '../../../common/CustomPanel';
import MapsTable from './MapsTable';
import { Navigate } from 'react-router-dom';

import MapsModal from './MapsModal';
import { isEmpty } from '../../../../misc/utils';
import { translateComp } from '../../../../i18n/utils';

/**
 * Klasse fuer einen Dashboard von Geraeten
 *
 * @export
 * @class DeviceDashboard
 * @extends {Component}
 */
    export default function MapsDashboard({       
        requestMapsData, 
        updateMaps,
        addMaps,
        deleteMaps,
        setDefaultMaps,
        domainState: {
            mapsList: { items },
            isValidMaps
        },
        userState: {
            authentication: { user: authUser },
        },
        ...props
    }) {    
    const [mapsItems, setMapsItems] = useState(items);

    useEffect(() => {
        requestMapsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setMapsItems(items);
    }, [items]);

    if (!authUser) {
        return <Navigate to='/login' />;
    }

    const deleteMapsHandler = maps => {
        if (!isEmpty(maps._id)) {
            deleteMaps(maps);
        }
    };

    const changeDefaultHandler = maps => {
        if (!isEmpty(maps._id)) {
            setDefaultMaps(maps);
        }
    }

    const renderAddButton = () => {
        
        return (
            <MapsModal
                icon='plus'
                text={translateComp('maps.add', 'Maps Hinzufügen')}
                buttonText={translateComp('common.add', 'Hinzufügen')}
                submitCallback={addMaps}
                checkMaps={props.checkMaps}
            />
        );
    };

    return (
        <div>
            <CustomPanel
                title={translateComp('common.maps', 'MAPS')}
                description={translateComp('maps.description', 'Karten Dienste für die App')}
                rightContent={renderAddButton}
            >

                <MapsTable 
                    mapsList={mapsItems}
                    submitMapsCallback={updateMaps} 
                    deleteMapsCallback={deleteMapsHandler}
                    checkMaps={props.checkMaps}
                    changeDefaultCallback={changeDefaultHandler}
                />
            </CustomPanel>
        </div>
    );
}
