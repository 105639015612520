import React, { Component } from 'react';
import { Button, Form, Icon, Segment } from 'semantic-ui-react';

import SimpleModal from './SimpleModal';
import IconButton from './IconButton';

import { translateComp, translateString } from '../../i18n/utils';
import { isEmpty, isFunction } from '../../misc/utils';
import { COLOR_SET } from '../../misc/const';

export default class AdvancedModal extends Component {
    renderButton(props) {
        const { buttonIcon, buttonStyle, buttonText, disabled, onClick } = this.props;

        if (isEmpty(buttonText)) {
            return (
                <IconButton
                    onClick={() => {
                        if (isFunction(onClick)) {
                            onClick();
                        }
                        props.onOpen();
                    }}
                    icon={buttonIcon}
                    disabled={disabled}
                    style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, ...buttonStyle }}
                />
            );
        }
        return (
            <Button
                icon
                style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, ...buttonStyle }}
                labelPosition='left'
                onClick={() => {
                    if (isFunction(onClick)) {
                        onClick();
                    }
                    props.onOpen();
                }}
                disabled={disabled}
            >
                <Icon name={buttonIcon} />
                {buttonText}
            </Button>
        );
    }

    renderActionButtons(props) {
        const { cancelCallback, submitCallback, open = false, isValidForm, submitDisabled } = this.props;
        
        return (
            <div>
                <Button
                    style={{ backgroundColor: COLOR_SET.SECONDARY }}
                    content={translateComp('common.abort', 'Abbrechen')}
                    onClick={() => {
                        cancelCallback();
                        props.onAction(false);
                    }}
                />
                <Button
                    icon
                    color='blue'
                    disabled={submitDisabled}
                    style={{ backgroundColor: COLOR_SET.PRIMARY }}
                    labelPosition='left'
                    onClick={() => {
                        if (isValidForm) {
                            submitCallback();
                            props.onAction(open);
                        }
                    }}
                >
                    <Icon name='save' />
                    {translateString('common.save')}
                </Button>
            </div>
        );
    }

    render() {
        const { children, headerIcon, headerText } = this.props;

        return (
            <SimpleModal
                button={props => this.renderButton(props)}
                header={
                    <div>
                        <Icon name={headerIcon} />
                        {headerText}
                    </div>
                }
                content={
                    <div>
                        <Segment basic vertical>
                            {children}
                        </Segment>
                        <Form.Group widths='equal' style={{ float: 'right' }}>
                            <div>
                                <Icon.Group>
                                    <Icon name='asterisk' color='red' size='tiny' corner='top right' />
                                </Icon.Group>
                                {translateString('common.mandatory')}
                            </div>
                        </Form.Group>
                    </div>
                }
                actions={props => this.renderActionButtons(props)}
            />
        );
    }
}
