import React from 'react';
import { Form, Input, Icon } from 'semantic-ui-react';

import { translateString } from '../../i18n/utils';
import { isEmpty } from '../../misc/utils';

import '../../css/labelinputfield.css';

/**
 * Komponente fuer Input
 * @export
 * @param {*} props
 * @returns
 */
export default function LabelInputField({
    id,
    label,
    type,
    disabled = false,
    onChange,
    onBlur,
    value,
    placeholder,
    hasError = false,
    errorMessage,
    control = Input,
    style,
    required,
}) {
    const errMessage = hasError ? { content: errorMessage } : false;
    return (
        <div className='field'>
            <Form.Field
                control={control}
                id={id}
                label={
                    <div>
                        {label}
                        {required && (
                            <Icon.Group>
                                <Icon name='asterisk' color='red' size='tiny' corner='top right' />
                            </Icon.Group>
                        )}
                    </div>
                }
                type={type}
                disabled={disabled}
                placeholder={isEmpty(placeholder) ? translateString('common.placeholder') : placeholder}
                error={errMessage}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur ? e => onBlur(e.target.value) : null}
                value={value}
                style={style}
            />
            {!hasError && <div className='ui pointing above prompt label messagespacer' role='alert' aria-atomic='true' />}
        </div>
    );
}
