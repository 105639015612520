import { sendErrorMessage } from '../appActions';

import * as API from '../../backend/requests/deviceRequests';
import * as types from '../utils/actionTypes';

/**
 * Redux Action fuer die Abfrage der Geraetedaten
 * @return {Function} Die Redux Action
 */
export function requestDeviceData() {
    return {
        types: [types.DEVICES_REQUEST, types.DEVICES_COMMIT, types.DEVICES_ROLLBACK],
        callApi: () => API.getDevices(),
    };
}

export function updateDevice(device) {
    return dispatch => {
        API.postDevice(device)
            .then(() => {
                dispatch(requestDeviceData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
}

export function deleteDevice(deviceId) {
    return dispatch => {
        API.deleteDevice(deviceId)
            .then(() => {
                dispatch(requestDeviceData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
}
