import React from 'react';
import { Table } from 'semantic-ui-react';

import RightsTableRow from './RightsTableRow';

import { translateComp, translateString } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';

export default function RightsTable({ authUser, user, style, callback }) {
    return (
        <Table fixed celled unstackable striped style={style} definition={!isEmpty(user)}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell style={{ textAlign: 'center' }}>{translateComp('rights.read', 'Lesen')}</Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>{translateComp('rights.create', 'Erstellen')}</Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>{translateComp('rights.update', 'Aktualisieren')}</Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>{translateComp('rights.delete', 'Löschen')}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <RightsTableRow
                    rowTitle={translateString('common.user')}
                    rights={user.user}
                    accessor='user'
                    callback={callback}
                    enabledRightsObj={authUser.user}
                />
                <RightsTableRow
                    rowTitle={translateString('common.operators')}
                    rights={user.operator}
                    accessor='operator'
                    callback={callback}
                    enabledRightsObj={authUser.operator}
                />
                <RightsTableRow
                    rowTitle={translateString('common.devices')}
                    rights={user.device}
                    accessor='device'
                    callback={callback}
                    enabledRightsObj={authUser.device}
                />
            </Table.Body>
        </Table>
    );
}
