import React, { useState } from 'react';
import { Button, Divider, Form, Icon } from 'semantic-ui-react';
import { Navigate } from 'react-router-dom';

import CustomPanel from '../../../common/CustomPanel';
import UserForm from './UserForm';
import SettingsForm from './SettingsForm';
import PasswordForm from './PasswordForm';

import { translateComp, translateString } from '../../../../i18n/utils';
import { objectHasEmptyValues, isEmpty, isValidMailAddress } from '../../../../misc/utils';
import { COLOR_SET } from '../../../../misc/const';

/**
 * Klasse fuer ein Dashboarmodul fuer den aktuell eingeloggten Benutzer
 *
 * @export
 * @class UserInfoDashboard
 * @extends {DashboardViewModule}
 */
export default function UserInfoDashboard({
    user,
    settings,
    changePassword,
    updateSettings,
    updateUser,
    sendSuccessMessage,
    updateUserCallback,
}) {
    const [isUserValid, setUserValid] = useState(true);
    const [isPasswordValid, setPasswordValid] = useState(true);
    const [_user, setUser] = useState(user);
    const EMPTY_PASSWORD_PAIR = { old: '', new: '' };
    const [passwordPair, setPasswordPair] = useState(EMPTY_PASSWORD_PAIR);

    if (!user) {
        return <Navigate to='/login' />;
    }

    const resetPasswordPair = () => {
        setPasswordPair(EMPTY_PASSWORD_PAIR);
    };

    const changePasswordPairHandler = aPasswordPair => {
        setPasswordPair(aPasswordPair);
    };

    const saveChangedPassword = () => {
        if (!objectHasEmptyValues(passwordPair)) {
            changePassword(passwordPair.old, passwordPair.new);
            resetPasswordPair();
        } else {
            setPasswordValid(false);
        }
    };

    const saveUser = () => {
        if (objectHasEmptyValues(_user) || !isValidMailAddress(_user.mail)) {
            setUserValid(false);
        } else {
            setUserValid(true);
            delete _user.password;
            updateUser(_user);
            sendSuccessMessage('messages.userUpdated');
            updateUserCallback(_user);
        }
    };

    const onSaveClick = () => {
        setPasswordValid(true);
        if (!isEmpty(passwordPair.old) || !isEmpty(passwordPair.new)) {
            saveChangedPassword();
        }
        if (user !== _user) {
            saveUser();
        }
    };

    return (
        <div>
            <CustomPanel
                title={translateComp('settings.settings', 'Settings')}
                description={translateComp('settings.description', 'Einstellungen des angemeldeten Benutzers')}
            >
                <Form>
                    <UserForm user={_user} onChange={setUser} />
                    <Divider section hidden />
                    <PasswordForm
                        passwordPair={passwordPair}
                        setPasswordCallback={changePasswordPairHandler}
                        isValid={isPasswordValid}
                    />
                    <Divider section hidden />
                    <SettingsForm settings={settings} onChange={updateSettings} />
                    <div style={{ float: 'right' }}>
                        <Icon.Group>
                            <Icon name='asterisk' color='red' size='tiny' corner='top right' />
                        </Icon.Group>
                        {translateString('common.mandatory')}
                    </div>
                </Form>
            </CustomPanel>
            <div className='ui divider' />
            <div style={{ float: 'right' }}>
                <Button
                    icon
                    color='blue'
                    style={{ backgroundColor: COLOR_SET.PRIMARY }}
                    labelPosition='left'
                    onClick={() => {
                        if (isUserValid && isPasswordValid) {
                            onSaveClick();
                        }
                    }}
                >
                    <Icon name='save' />
                    {translateString('common.save')}
                </Button>
            </div>
        </div>
    );
}
