// @flow
import React from 'react';
import { FormField } from 'semantic-ui-react';

export default function MapsLayerElement({ layerElement, isChecked, callback }) {
    const callbackHandler = (element) => {
        callback({name: layerElement.name, checked: element.target.checked} );
    }
    return (
        <FormField label={layerElement?.title} control='input' type='checkbox' checked={isChecked} onChange={callbackHandler}/>
    );
}
