// Pfade
export const DEFAULT = '/api/gully/administrator';

// Authentifizierung
export const AUTH = `${DEFAULT}/auth/verify`;
export const WHOAMI = `${DEFAULT}/users/whoami`;

// Setup
export const SETUP = `${DEFAULT}/setup`;
export const FORGOTPASSWORD = `${DEFAULT}/forgotpassword`;
export const RESETPASSWORD = `${DEFAULT}/resetpassword`;

// Connection
export const PING = `${DEFAULT}/ping`;

// User
export const USER = `${DEFAULT}/users`;
export const ALL_USERS = `${USER}/all`;
export const CREATE_USER = `${USER}/create`;

// Operators
export const OPERATOR = `${DEFAULT}/operators`;
export const ALL_OPERATORS = `${OPERATOR}/all`;
export const CREATE_OPERATOR = `${OPERATOR}/create`;

// Licenses
export const LICENSE = `${DEFAULT}/licenses`;
export const LICENSE_KEY = `${LICENSE}/key`;
export const LICENSE_DATA = `${LICENSE}/data`;

// Devices
export const DEVICE = `${DEFAULT}/devices`;
export const ALL_DEVICES = `${DEVICE}/all`;

// OperatorDevices
export const OPERATORDEVICE = `${DEFAULT}/operatorDevices`;
export const ALL_OPERATORDEVICES = `${OPERATORDEVICE}/all`;
export const COUNT_OPERATOR_AND_DEVICES = `${OPERATORDEVICE}/count`;

// Maps
export const MAPS = `${DEFAULT}/maps`;
export const ALL_MAPS = `${MAPS}/all`;
export const CREATE_MAPS = `${MAPS}/create`;
export const CHECK_MAPS = `${MAPS}/check`;
export const GET_LAYER = `${MAPS}/layers`;
export const SETDEFAULT_MAPS = `setdefault`;
