import React, { useState } from 'react';

import { Icon } from 'semantic-ui-react';
import { translateString } from '../../i18n/utils';

/**
 * Komponente fuer abgesicherten Input
 *
 * @export
 * @param {*} {
 * 	disabled = false,
 * 	placeholder = translateString('placeholder.input'),
 * 	changeCallback,
 * 	icon = 'lock',
 * 	handleKeyPress,
 * 	autoFocus = false,
 * }
 * @returns
 */
export default function SecureInput({
    disabled = false,
    placeholder = translateString('common.placeholder'),
    changeCallback,
    onBlur,
    icon = 'lock',
    required,
    label,
    hasError,
    errorMessage,
    handleKeyPress,
    disableAutoComplete = false,
    value,
    id,
    autoFocus = false,
}) {
    const [showPass, setShowPass] = useState(false);
    const eyeIcon = showPass ? 'eye slash' : 'eye';
    const errMessage = hasError ? errorMessage : '';
    return (
        <div className={hasError ? 'error field' : 'field'}>
            <div>
                {label}
                {required && (
                    <Icon.Group>
                        <Icon name='asterisk' color='red' size='tiny' corner='top right' />
                    </Icon.Group>
                )}
            </div>
            <div className='ui action left icon fluid input'>
                <input
                    id={id}
                    type={showPass ? 'text' : 'password'}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    autoComplete={disableAutoComplete ? 'one-time-code' : ''}
                    disabled={disabled}
                    onKeyPress={handleKeyPress}
                    onBlur={onBlur ? e => onBlur(e.target.value) : null}
                    value={value}
                    onChange={e => changeCallback(e.target.value)}
                />
                <button type='button' className='ui icon button' onClick={() => setShowPass(!showPass)}>
                    <i aria-hidden='true' className={`${eyeIcon} icon`} />
                </button>
                <i aria-hidden='true' className={`${icon} icon`} />
            </div>
            {hasError && (
                <div className='ui pointing above prompt label' id='password-error-message' role='alert' aria-atomic='true'>
                    {errMessage}
                </div>
            )}
        </div>
    );
}
