// @flow
import React from 'react';
import { Container, Table } from 'semantic-ui-react';

import MapsModal from './MapsModal';

import DeleteConfirmDialog from '../../../common/DeleteConfirmDialog';
import ToggleDefaultDialog from '../../../common/ToggleDefaultDialog';

import { translateComp, translateString } from '../../../../i18n/utils';

export default function MapsTableRow({ maps, submitMaps, deleteCallback, checkMaps, changeDefaultCallback }) {

    const cancelCallbackHandler = () => {
        submitMaps(maps);
    };

    const isEditable = maps.accessor !== 'mapbox';
    const deleteCallbackHandler = () => {
        deleteCallback(maps);
    };

    const changeDefaultCallbackHandler = () => {
        // TODO
        changeDefaultCallback(maps);

    };
    const deleteMessage = translateString('messages.deleteMapsMessage', {
        name: maps.title
    });

    const defaultToggleMessage = translateString('messages.defaultToggleMapsMessage', {
        name: maps.title
    });
    
    const getMapsUrl = aMaps => {
        if(aMaps.accessor === 'mapbox'){
            // unser mapbox Beispiel ohne den access_token anzeigen:
            return 'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png';
        }
        else{
            return maps.url;
        }
    }

    // const mapsUrl = getMapsUrl(maps);
    return (
        <Table.Row>
            <Table.Cell>{maps.title}</Table.Cell>
            <Table.Cell><Container style={{width:'400px'}}><p style={{overflow: 'auto'}}>{getMapsUrl(maps)}</p></Container></Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>

                <ToggleDefaultDialog
                    closeCallback={cancelCallbackHandler}
                    confirmCallback={changeDefaultCallbackHandler}
                    header={translateString('maps.toggledefault')}
                    content={defaultToggleMessage}
                    cancelButtonText={translateString('common.abort')}
                    confirmButtonText={translateString('common.ok')}
                    isDefault={maps.default}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {isEditable &&
                <MapsModal
                    icon='pencil'
                    text={translateComp('common.maps', 'Maps')}
                    submitCallback={submitMaps}
                    maps={maps}
                    checkMaps={checkMaps}
                />
                }
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {isEditable &&
                <DeleteConfirmDialog
                    closeCallback={cancelCallbackHandler}
                    confirmCallback={deleteCallbackHandler}
                    header={translateString('maps.delete')}
                    content={deleteMessage}
                    cancelButtonText={translateString('common.abort')}
                    confirmButtonText={translateString('common.ok')}
                    disabled={maps.default}
                />
                }
            </Table.Cell>
        </Table.Row>
    );
}
