import React from 'react';
import { Table } from 'semantic-ui-react';

export default function OperatorDeviceTableRow({ item, keyStr }) {
    const localeTimestamp = new Date(item.timestamp).toLocaleString();
    return (
        <Table.Row key={keyStr}>
            <Table.Cell>{localeTimestamp}</Table.Cell>
            <Table.Cell>{item.operator.username} {item.operator.mail}</Table.Cell>
            <Table.Cell>{item.device.name}</Table.Cell>
        </Table.Row>
    );
}
