import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';

import Register from './Register';

import { translateComp, translateString } from '../../i18n/utils';

/**
 * Klasse fuer einen Container, der die Authentifizierung regelt
 *
 * @export
 * @class AuthContainer
 * @extends {Component}
 */
export default class RegisterContainer extends Component<Props, State> {
    /**
     * Fuehrt einen Setupversuch durch, bei dem der erste User eingerichtet wird
     * @param  {String} mail Der Benutzername
     * @param  {String} password Das Passwort
     * @param  {String} setupKey aus dem gully-api-administrator Service
     */
    handleSetup = (mail: string, password: string, setupKey: string): void => {
        const { setup } = this.props;
        setup(mail, password, setupKey);
    };

    renderRegister = (): void => {
        return (
            <Register
                title={translateComp('common.setup', 'Setup')}
                mail={translateString('common.mail')}
                password={translateString('common.password')}
                setupKey={translateString('common.key')}
                button={translateComp('common.setup', 'Setup')}
                callback={this.handleSetup}
            />
        );
    };

    /**
     * Rendert den Grid
     * @memberof AuthContainer
     * @return {JSX}
     */
    render() {
        return (
            <div>
                <Grid centered>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column style={{ marginTop: 50 }}>{this.renderRegister()}</Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}
