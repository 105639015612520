import React from 'react';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

/**
 * HOC fuer das erstellen einer Übersetzung
 */
export const translateComp = (key, content) => {
    return <Trans i18nKey={key}>{content}</Trans>;
};

export const translateString = (key, placeholder) => {
    let translatedString = i18next.t(key);
    if (placeholder) {
        const replaceKeys = Object.keys(placeholder);
        replaceKeys.forEach(placeholderKey => {
            translatedString = translatedString.replace(`{{${placeholderKey}}}`, placeholder[placeholderKey]);
        });
    }
    return translatedString;
};
