import { toast } from 'react-toastify';

import { translateString } from '../i18n/utils';
import { TOASTIFY_SEVERITY } from './const';

/**
 * Erzeugt eine Nachricht, die dem Benutzer angezeigt wird
 * @param  {String} text     Text der Nachricht
 * @param  {String} severity Schwere der Nachricht
 */
export default function showToast(text, severity) {
    const messageText = text.includes('.') ? translateString(text) : text;

    let autoCloseMS = severity === TOASTIFY_SEVERITY.ERROR ? 5000 : 2000;
    if (messageText.length > 0) {
        const autoCloseMSHelper = messageText.length * 70;

        if (autoCloseMSHelper > 2000) {
            autoCloseMS = Number.parseInt((autoCloseMSHelper / 1000).toFixed(), 10) * 1000;
        }
    }

    switch (severity) {
        case TOASTIFY_SEVERITY.ERROR:
            toast.error(messageText, {
                autoClose: autoCloseMS,
            });
            break;
        case TOASTIFY_SEVERITY.INFO:
            toast.info(messageText, {
                autoClose: autoCloseMS,
            });
            break;
        case TOASTIFY_SEVERITY.SUCCESS:
            toast.success(messageText, {
                autoClose: autoCloseMS,
            });
            break;
        case TOASTIFY_SEVERITY.WARNING:
            toast.warn(messageText, {
                autoClose: autoCloseMS,
            });
            break;
        default:
            toast.info(messageText, {
                autoClose: autoCloseMS,
            });
            break;
    }
}
