import { sendErrorMessage } from '../appActions';

import * as API from '../../backend/requests/mapsRequests';
import * as types from '../utils/actionTypes';
import { translateString } from '../../i18n/utils';

/**
 * Redux Action fuer die Abfrage der Benutzerdaten
 * @return {Function} Die Redux Action
 */
export function requestMapsData() {
    return {
        types: [types.MAPS_REQUEST, types.MAPS_COMMIT, types.MAPS_ROLLBACK],
        callApi: () => API.getMapsList(),
    };
}

export function checkMaps(maps) {
    return dispatch => {
        return API.postCheckMaps(maps)
            .then(() => {
                return true;
            })
            .catch(err => {
                return err;
            });
    };
}

export function addMaps(maps) {
    return dispatch => {
        API.postCreateMaps(maps)
            .then(() => {
                dispatch(requestMapsData());
            })
            .catch(err => {
                if(err?.response?.status === 409){
                    dispatch(sendErrorMessage(new Error(translateString('maps.messages.unique_err'))));
                }else{
                    dispatch(sendErrorMessage(err));
                }               
            });
    };
}

export function getLayer(capabilitiesUrl) {
    return API.getLayer( capabilitiesUrl)
    .then(result => {
        return {layers: result.data.layers, version: result.data.version};    
    })
    .catch(error => {
        sendErrorMessage(error);
    });
}

export function updateMaps(maps) {
    return dispatch => {
        API.postMaps(maps)
            .then(() => {
                dispatch(requestMapsData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
}

export function deleteMaps(maps) {
    return dispatch => {
        API.deleteMaps(maps._id)
            .then(() => {
                dispatch(requestMapsData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
}

export function setDefaultMaps(maps) {
    return dispatch => {
        API.setDefaultMaps(maps)
            .then(() => {
                dispatch(requestMapsData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
    
}


