import React from 'react';
import { Header, Segment, Divider } from 'semantic-ui-react';
import CustomPanelTitleDescription from './CustomPanelTitleDescription';
import Loading from './Loading';

import '../../css/custompanel.css';

/**
 * Komponente fuer das Setup
 * @type {Setup}
 */
export default function CustomPanel({ children, title, loader = false, rightContent, description }) {
    const RightContent = rightContent;

    return (
        <div className='panelContainer'>
            <Loading show={loader} />
            <Segment basic textAlign='center'>
                <Header as='h2'>
                    <div className='panelHeader'>
                        <CustomPanelTitleDescription title={title} description={description} />
                        {rightContent && (
                            <div className='panelHeaderRightContent'>
                                <RightContent />
                            </div>
                        )}
                    </div>
                </Header>
                <Divider />
                <div className='panelChildren'>{children}</div>
            </Segment>
        </div>
    );
}
