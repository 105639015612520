import { combineReducers } from 'redux';
import showToast from '../misc/toastConfig';

// Reducers
import domainReducer from './domainReducer';
import appReducer from './appReducer';
import userReducer from './userReducer';

/*
 * Domain data:
 * Data that the application needs to show, use, or modify
 * (such as "all of the Todos retrieved from the server")
 *
 * App state:
 * Data that is specific to the application's behavior
 * (such as "Todo #5 is currently selected", or "there is a request in progress to fetch Todos")
 *
 * User state:
 * Data about the current User
 * (such as Username or Token)
 */

// Combine Reducers
const appReducers = combineReducers({
    appState: appReducer,
    domainState: domainReducer,
    userState: userReducer,
});

/**
 * Hauptreducer, der die Action an alle anderen reducer weitergibt
 * @param  {Object} [state] Der aktuelle Status im Redux Store
 * @param  {Object} action                Action, die ausgefuehrt werden soll
 * @return {Object}                       Der neue Status
 */
const rootReducer = (state, action) => {
    if (action.type === 'DISPATCH_MESSAGE') {
        showToast(action.text, action.severity, action.position);
    }
    return appReducers(state, action);
};

export default rootReducer;
