import React, { useEffect, useState } from 'react';

import { Navigate } from 'react-router-dom';
import CustomPanel from '../../../common/CustomPanel';
import UserTable from './UserTable';
import UserAndRightsModal from './UserAndRightsModal';

import { isEmpty } from '../../../../misc/utils';
import { translateComp } from '../../../../i18n/utils';

function removeSuperAdminFromItems(items, setHasCustomItems, setItemsWithoutAdmin) {
    // Der zuerst angelegte User ist der SuperAdmin,
    // der braucht nicht angezeigt zu werden da er nicht verändert werden sollte
    const noEmptyItems = !isEmpty(items);
    const moreThanOneItem = Object.keys(items).length > 1;

    if (noEmptyItems && moreThanOneItem) {
        setHasCustomItems(true);
        const userEntryArr = [];
        for (let index = 1; index < Object.keys(items).length; index++) {
            userEntryArr.push(items[index]);
        }
        setItemsWithoutAdmin(userEntryArr);
    }
}

/**
 * Function fuer ein UserDashboard von Users
 *
 * @export
 * @function UserDashboard
 */

export default function UserDashboard({
    requestUserData,
    addUser,
    deleteUser,
    updateUser,
    domainState: {
        users: { items = [], isFetching },
    },
    userState: {
        authentication: { user: authUser },
    },
}) {
    const [itemsWithoutAdmin, setItemsWithoutAdmin] = useState(items);
    const [hasCustomItems, setHasCustomItems] = useState(false);

    useEffect(() => {
        requestUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        removeSuperAdminFromItems(items, setHasCustomItems, setItemsWithoutAdmin);
    }, [items]);

    if (!authUser) {
        return <Navigate to='/login' />;
    }
    
    const deleteUserHandler = user => {
        if (!isEmpty(user._id)) {
            deleteUser(user);
        }
    };

    const renderAddButton = () => {
        return (
            <UserAndRightsModal
                disabled={!authUser.user.create}
                icon='plus'
                text={translateComp('user.add', 'Administrator Hinzufügen')}
                buttonText={translateComp('common.add', 'Hinzufügen')}
                submitCallback={addUser}
                cancelCallback={()=>{}}
                authUser={authUser}
                hasCustomItems={hasCustomItems}
            />
        );
    };

    return (
        <CustomPanel
            title={translateComp('common.user', 'User')}
            loader={isFetching}
            rightContent={() => renderAddButton()}
            description={translateComp('user.description', 'Verwaltung der Administratoren für diese Web-Oberfläche')}
        >
                <UserTable
                    users={itemsWithoutAdmin}
                    rights={authUser.user}
                    submitUser={updateUser}
                    deleteCallback={deleteUserHandler}
                    authUser={authUser}
                />
        </CustomPanel>
    );
}
