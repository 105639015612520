import * as types from '../actions/utils/actionTypes';

const initialState = {
    dashboardView: {},
    settings: {
        lang: 'de',
    },
};

/**
 * Updatet den Store und wird durch appActions aufgerufen
 * @export
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
// eslint-disable-next-line default-param-last
export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_DASHBOARD_VIEW:
            return {
                ...state,
                dashboardView: action.view,
            };
        case types.SET_SETTINGS:
            return {
                ...state,
                settings: action.settings,
            };
        default:
            return state;
    }
}
