/**
 * Basisklasse fuer ein Element auf dem Formular
 *
 * @export
 * @class BaseElement
 */
export default class BaseElement {
    id: string;

    /**
     * Creates an instance of BaseElement.
     * @param {string} id Id des Elements
     * @memberof BaseElement
     */
    constructor(id: string) {
        this.id = id;
    }

    /**
     * Erzeugt das React Element
     *
     * @param {Object} object Objekt mit den Werten
     * @param {Function} onChange Funktion, die bei Aenderung dieses Wertes gerufen wird
     * @param {Boolean} newObj Ob es ein neues Objekt ist
     * @memberof BaseElement
     */
    build(): ?React$Element<any> {
        return null;
    }
}
