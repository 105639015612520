import React, { Component } from 'react';
import CustomPanel from '../../../common/CustomPanel';
import DeviceTable from './DeviceTable';

import { isEmpty } from '../../../../misc/utils';
import { TOASTIFY_SEVERITY } from '../../../../misc/const';
import { translateComp } from '../../../../i18n/utils';

/**
 * Klasse fuer einen Dashboard von Geraeten
 *
 * @export
 * @class DeviceDashboard
 * @extends {Component}
 */
export default class DeviceDashboard extends Component {
    UNSAFE_componentWillMount() {
        const { requestDeviceData } = this.props;

        requestDeviceData();
    }

    deleteDeviceHandler = device => {
        const { deleteDevice, sendMessage } = this.props;

        if (!isEmpty(device._id)) {
            if (device.active) {
                sendMessage({
                    text: 'messages.deviceActive',
                    severity: TOASTIFY_SEVERITY.WARNING,
                });
            } else {
                deleteDevice(device.deviceId);
            }
        }
    };

    updateDeviceHandler = updateDeviceData => {
        const { updateDevice } = this.props;

        if (!isEmpty(updateDeviceData.deviceId)) {
            updateDevice(updateDeviceData);
        }
    };

    render() {
        const {
            domainState: {
                devices: { items = [], isFetching },
            },
            userState: {
                authentication: { user: authUser },
            },
        } = this.props;

        return (
            <CustomPanel
                title={translateComp('common.devices', 'Devices')}
                loader={isFetching}
                description={translateComp('device.description', 'Mobile Geräte die kürzlich mit dem Server verbunden waren')}
            >
                <DeviceTable
                    devices={items}
                    rights={authUser.device}
                    submitDevice={this.updateDeviceHandler}
                    deleteCallback={this.deleteDeviceHandler}
                />
            </CustomPanel>
        );
    }
}
