import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
// import { COLOR_SET } from '../../misc/const';

/**
 * Komponente fuer das Setup
 * @type {Setup}
 */
export default function CustomPanelTitleDescription({ title, description }) {
    return (
        <div className='panelHeaderText'>
            {description && (
                <Popup
                    content={description}
                    trigger={
                        <Button icon size='massive'>
                            <Icon name='info circle' />
                        </Button>
                    }
                    position='bottom center'
                />
            )}
            {title}
        </div>
    );
}
