/* eslint-disable no-case-declarations */
import * as types from '../actions/utils/actionTypes';

const initialState = {
    operators: {
        isFetching: false,
        items: [],
        lastUpdated: null,
    },
    devices: {
        isFetching: false,
        items: [],
        lastUpdated: null,
    },
    users: {
        isFetching: false,
        items: [],
        lastUpdated: null,
    },
    license: {
        isFetching: false,
        data: {},
        key: '',
        lastUpdated: null,
    },
    operatorDevices: {
        isFetching: false,
        items: [],
        lastUpdated: null,
    },
    mapsList: {
        isFetching: false,
        items: [],
        lastUpdated: null,        
    }
};

/**
 * Updatet den Store und wird durch domainActions aufgerufen
 * @export
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
// eslint-disable-next-line default-param-last
export default function domainReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.OPERATORS_REQUEST:
            return {
                ...state,
                operators: {
                    isFetching: true,
                    lastUpdated: state.operators.lastUpdated,
                    items: state.operators.items,
                },
            };
        case types.OPERATORS_COMMIT:
            return {
                ...state,
                operators: {
                    isFetching: false,
                    lastUpdated: new Date(),
                    items: payload.data.operators,
                },
            };
        case types.OPERATORS_ROLLBACK:
            return {
                ...state,
                operators: {
                    isFetching: false,
                    lastUpdated: state.operators.lastUpdated,
                    items: [],
                },
            };
        case types.DEVICES_REQUEST:
            return {
                ...state,
                devices: {
                    isFetching: true,
                    lastUpdated: state.devices.lastUpdated,
                    items: state.devices.items,
                },
            };
        case types.DEVICES_COMMIT:
            return {
                ...state,
                devices: {
                    isFetching: false,
                    lastUpdated: new Date(),
                    items: payload.data.devices,
                },
            };
        case types.DEVICES_ROLLBACK:
            return {
                ...state,
                devices: {
                    isFetching: false,
                    lastUpdated: state.devices.lastUpdated,
                    items: [],
                },
            };
        case types.USERS_REQUEST:
            return {
                ...state,
                users: {
                    isFetching: true,
                    lastUpdated: state.users.lastUpdated,
                    items: state.users.items,
                },
            };
        case types.USERS_COMMIT:
            return {
                ...state,
                users: {
                    isFetching: false,
                    lastUpdated: new Date(),
                    items: payload.data.users,
                },
            };
        case types.USERS_ROLLBACK:
            return {
                ...state,
                users: {
                    isFetching: false,
                    lastUpdated: state.users.lastUpdated,
                    items: [],
                },
            };
        case types.SET_LICENSEKEY:
            return {
                ...state,
                license: {
                    isFetching: false,
                    lastUpdated: state.license.lastUpdated,
                    data: state.license.data,
                    key: action.key,
                },
            };
        case types.LICENSE_REQUEST:
            return {
                ...state,
                license: {
                    isFetching: true,
                    lastUpdated: state.license.lastUpdated,
                    data: state.license.data,
                    key: state.license.key,
                },
            };
        case types.LICENSE_COMMIT:
            return {
                ...state,
                license: {
                    isFetching: false,
                    lastUpdated: new Date(),
                    data: payload.data.license,
                    key: state.license.key,
                },
            };
        case types.LICENSE_ROLLBACK:
            return {
                ...state,
                license: {
                    isFetching: false,
                    lastUpdated: state.license.lastUpdated,
                    data: {},
                    key: '',
                },
            };
        case types.OPERATORDEVICES_REQUEST:
            return {
                ...state,
                operatorDevices: {
                    isFetching: true,
                    lastUpdated: state.operatorDevices.lastUpdated,
                    items: state.operatorDevices.items,
                },
            };
        case types.OPERATORDEVICES_COMMIT:
            return {
                ...state,
                operatorDevices: {
                    isFetching: false,
                    lastUpdated: new Date(),
                    items: payload.data.operatorDevices,
                },
            };
        case types.OPERATORDEVICES_ROLLBACK:
            return {
                ...state,
                operatorDevices: {
                    isFetching: false,
                    lastUpdated: state.operatorDevices.lastUpdated,
                    items: [],
                },
            };
            case types.MAPS_REQUEST:
                return {
                    ...state,
                    mapsList: {
                        isFetching: true,
                        lastUpdated: state.mapsList.lastUpdated,
                        items: state.mapsList.items,
                    },
                };
            case types.MAPS_COMMIT:
                return {
                    ...state,
                    mapsList: {
                        isFetching: false,
                        lastUpdated: new Date(),
                        items: payload.data.mapsList,
                    },
                };
            case types.MAPS_ROLLBACK:
                return {
                    ...state,
                    mapsList: {
                        isFetching: false,
                        lastUpdated: state.mapsList.lastUpdated,
                        items: [],
                    },
                };
        default:
            return state;
    }
}
