// actions
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const DISPATCH_MESSAGE = 'DISPATCH_MESSAGE';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_RESET = 'LOGIN_RESET';
export const USER_DATA_RECEIVED = 'USER_DATA_RECEIVED';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const SETUP_NEEDED = 'SETUP_NEEDED';
export const SETUP_COMPLETED = 'SETUP_COMPLETED';
export const SETUP_RESET = 'SETUP_RESET';
export const SETUP_FAILED = 'SETUP_FAILED';
export const SWITCH_DASHBOARD_VIEW = 'SWITCH_DASHBOARD_VIEW';
export const LOGOUT = 'LOGOUT';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

// toastify severity
export const TOASTIFY_SEVERITY = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
};

// Warnmeldungen Lizenzwerte
export const LICENSE_EXPIRY_DAY_WARNING = 14;
export const LICENSE_EXPIRY_DAY_DANGER = 7;
export const LICENSE_EXPIRY_COUNT_WARNING = 70;
export const LICENSE_EXPIRY_COUNT_DANGER = 90;

export const FIELD_TYPE = {
    TEXT: 'text',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    MULTISELECT: 'multiselect',
    PASSWORD: 'password',
    STATUS: 'status',
};

export const ASSIGN_TYPE = {
    USER: 'USER',
    PROJECT: 'PROJECT',
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    COLOR_ACTIVE: '#a8abaf',
    COLOR_INACTIVE: '#dce1ea',
};

export const COLOR_SET = {
    PRIMARY: '#283659', // dark blue
    SECONDARY: '#F6B600', // orange
    ACTIVE: '#a8abaf', // gray1 wird nicht verwendet???
    INACTICE: '#dce1ea', // gray2  wird nicht verwendet???
    PRIMARY_TXT: 'white',
};

export const FILEUPLOAD_OPTION = {
    ADD: 'add',
    UPDATE: 'update',
    REMOVE: 'remove',
};

export const FILEUPLOAD_STATUS = {
    ADDED: 'added',
    QUEUED: 'queued',
};

export const PROJECT_STATUS = {
    NEW: 'NEW',
    PROGRESS: 'PROGRESS',
    SUCCESS: 'SUCCESS',
    BLOCKED: 'BLOCKED',
    FAILED: 'FAILED',
};

export const DEFAULT_PROJECTFILE_NAME = 'project.json';
