import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import registerServiceWorker from './registerServiceWorker';

import { init } from './actions/domainActions/commonActions';
import store from './store';
import './i18n/i18n';

import './css/index.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './components/App';
import FallbackUI from './components/common/FallbackUI';

store.dispatch(init());
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
        <Provider store={store}>
            <Suspense fallback={<FallbackUI />}>
                <App />
            </Suspense>
        </Provider>,
        document.getElementById('root'),
);
registerServiceWorker();
