import { get, post, del } from '../API';
import { ALL_OPERATORS, CREATE_OPERATOR, OPERATOR, ALL_OPERATORDEVICES } from '../paths';

export function getOperators() {
    return get(ALL_OPERATORS);
}

export function postCreateOperator(operator) {
    return post(CREATE_OPERATOR, operator);
}

export function postOperator(operator) {
    return post(`${OPERATOR}/${operator._id}`, operator);
}

export function postNewOperatorPassword(operator, password) {
    return post(`${OPERATOR}/${operator._id}/password`, { password: password });
}

export function deleteOperator(id) {
    return del(`${OPERATOR}/${id}`);
}

export function getOperatorDeviceMappings() {
    return get(ALL_OPERATORDEVICES);
}
