import React from 'react';
import { Divider, Header, Grid, Image } from 'semantic-ui-react';

import ibak from '../../images/ibak.png';

import '../../css/authcard.css';

/**
 * Erstellt einen Container fuer Authentifizierungskomponenten
 * @export
 * @param {*} { title, children, buttons }
 * @returns
 */
export default function RegisterCard({ title, children, buttons }) {
    return (
        <div>
            <Grid centered style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Divider horizontal>
                        <Header>
                            <Image
                                className='ibakImage'
                                src={ibak}
                                alt='IBAK'
                                verticalAlign='middle'
                                size='medium'
                                spaced='right'
                            />
                            {title}
                        </Header>
                    </Divider>
                    <div className='children'>{children}</div>
                    <div className='buttons'>{buttons}</div>
                </Grid.Column>
            </Grid>
        </div>
    );
}
