import React, { useState, useEffect } from 'react';

import AdvancedModal from '../../../common/AdvancedModal';
import DeviceForm from './DeviceForm';
import { objectHasEmptyValues } from '../../../../misc/utils';

export default function DeviceModal({
    disabled,
    icon,
    text,
    buttonText,
    submitCallback,
    device,
    deviceData,
    onChange,
    cancelCallback,
}) {
    const [isValidForm, setIsValidForm] = useState(true);
    useEffect(() => {
        if (objectHasEmptyValues(deviceData)) {
            setIsValidForm(false);
        } else {
            setIsValidForm(true);
        }
    }, [deviceData]);

    return (
        <AdvancedModal
            disabled={disabled}
            headerIcon={icon}
            headerText={text}
            buttonIcon={icon}
            buttonText={buttonText}
            submitCallback={() => {
                if (!objectHasEmptyValues(deviceData)) {
                    submitCallback(deviceData);
                }
            }}
            cancelCallback={cancelCallback}
            isValidForm={isValidForm}
        >
            <DeviceForm onChange={onChange} device={device} isValidForm={isValidForm} />
        </AdvancedModal>
    );
}
