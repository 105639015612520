import { get, post, del } from '../API';
import { ALL_DEVICES, DEVICE } from '../paths';

export function getDevices() {
    return get(ALL_DEVICES);
}

export function postDevice(device) {
    return post(`${DEVICE}/${device.deviceId}`, device);
}

export function deleteDevice(deviceId) {
    return del(`${DEVICE}/${deviceId}`);
}
