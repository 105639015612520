import { sendErrorMessage } from '../appActions';
import { makeActionCreator } from '../utils/actionCreator';
import * as API from '../../backend/requests/licenseRequests';
import * as types from '../utils/actionTypes';

/**
 * Setzt den Lizenzschluessel
 * @export
 * @param {*} key
 * @returns
 */
export const setLicenseKey = makeActionCreator(types.SET_LICENSEKEY, 'key');

/**
 * Redux Action fuer die Abfrage der Lizenzdaten
 * @return {Function} Die Redux Action
 */
export function requestLicenseData() {
    return {
        types: [types.LICENSE_REQUEST, types.LICENSE_COMMIT, types.LICENSE_ROLLBACK],
        callApi: () => API.getLicenseData(),
    };
}

export function requestLicenseKey() {
    return dispatch => {
        API.getLicenseKey()
            .then(result => {
                const { licenseKey } = result.data;
                if (licenseKey) {
                    dispatch(setLicenseKey(licenseKey));
                }
                dispatch(requestLicenseData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
}
