import React from 'react';
import { Form } from 'semantic-ui-react';

import LabelDropdownField from '../../../common/LabelDropdownField';

import { translateComp, translateString } from '../../../../i18n/utils';

export default function SettingsForm({ onChange, settings = { lang: 'de' } }) {
    return (
        <div>
            <Form.Group widths='equal'>
                <LabelDropdownField
                    id='lang'
                    label={translateComp('settings.language', 'Sprache')}
                    onChange={val => onChange({ ...settings, lang: val })}
                    value={settings.lang}
                    options={[
                        {
                            value: 'de',
                            text: translateString('languages.de'),
                        },
                        {
                            value: 'en',
                            text: translateString('languages.en'),
                        },
                    ]}
                />
            </Form.Group>
        </div>
    );
}
