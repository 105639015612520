import BaseValidationElement, { setIfExits } from './BaseValidationElement';

type BaseDisableElementOptions = {
    show?: boolean,
    showIfNew?: boolean,
    title: Text,
    required?: boolean,
    requiredIfNew?: boolean,
    accessor: string,
    validationMethod?: (Object, any, boolean) => boolean,
    disabled?: boolean,
    disableCallback?: (Object, boolean) => boolean,
};

export default class BaseDisableElement extends BaseValidationElement {
    disabled: boolean;

    disableCallback: ?(Object, boolean) => boolean = null;

    constructor(id: string, options: BaseDisableElementOptions) {
        super(id, {
            show: options.show,
            showIfNew: options.showIfNew,
            title: options.title,
            required: options.required,
            requiredIfNew: options.requiredIfNew,
            accessor: options.accessor,
            validationMethod: options.validationMethod,
        });

        this.disabled = setIfExits('disabled', options, false);
        this.disableCallback = options.disableCallback;
    }

    /**
     * Prueft ob das Feld deaktiviert sein soll
     *
     * @param {Object} obj Objekt mit den Werten
     * @param {boolean} newObj Ob es ein neues Objekt ist
     * @returns Ob es deaktiviert sein soll
     * @memberof TextBoxHelper
     */
    isDisabled(obj: Object, newObj: boolean): boolean {
        if (this.disableCallback) {
            return this.disableCallback(obj, newObj);
        }

        return this.disabled;
    }
}
