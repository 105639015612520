import Cookie from 'js-cookie';
import * as Environment from './environmentHelper';
import { isEmpty } from '../utils';

export const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);

export const inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);

// 11h
export const in11Hours = 0.5;

// 5h
export const in5Hours = 0.25;

// 2h
export const in2Hours = 0.125;

// 30 min
export const in30Minutes = 0.0625;

const _cookieName = Environment.cookieName;

const getCookieName = (cookieName = null) => {
    if (isEmpty(cookieName)) {
        return _cookieName;
    }
    return cookieName;
};

export const set = (value, cookieName, days = in2Hours) => {
    return Cookie.set(getCookieName(cookieName), value, { expires: days });
};

export const get = cookieName => {
    return Cookie.get(getCookieName(cookieName));
};

export const remove = cookieName => {
    return Cookie.remove(getCookieName(cookieName));
};

export const deleteAll = neededAttributes => {
    Object.keys(Cookie.get()).forEach(cookieName => {
        Cookie.remove(cookieName, neededAttributes);
    });
};
