import React, { useState } from 'react';
import SimpleModalRemoteControlled from './SimpleModalRemoteControlled';
import ModalActionButton from './ModalActionButton';
import ModalStartButton from './ModalStartButton';

/**
 * Klasse fuer einen einfachen Dialog mit Ja/Nein Option soll andere Zeilen mit aktualisieren,
 * Es soll nur einen Default WMS Dienst geben
 */
export default function ToggleDefaultDialog({
    closeCallback,
    confirmCallback,
    header,
    content,
    cancelButtonText,
    confirmButtonText,
    disabled,
    isDefault=false
}) {
    const [open, setOpen] = useState(false);
    
    const displayButtonColor = isDefault? 'green': 'red';
    return (
        <SimpleModalRemoteControlled
            button={(props) => <ModalStartButton buttonStyle={{backgroundColor: displayButtonColor}}  disabled={disabled} onOpen={() => setOpen(true)}/>}
            isOpen={open}
            openCallback={()=>setOpen(true)}
            closeCallback={()=>setOpen(false)}
            header={header}
            content={content}
            actions={(props) => 
            <ModalActionButton 
                submitIcon='squareCheck'
                submitText='common.ok'
                isValidForm={true}
                submitCallback={() => {
                        setOpen(false);
                        if(confirmCallback) confirmCallback();
                    }
                } 
                cancelCallback={() => {
                        setOpen(false);
                        if (closeCallback) closeCallback();
                    }
                }
            />}
        />
    );
}
