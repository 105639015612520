import React from 'react';
import { Table } from 'semantic-ui-react';
import { v1 } from 'uuid';

import UserTableRow from './UserTableRow';

import { translateComp } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';

import '../../../../css/App.css';

export default function UserTable({ rights, users, submitUser, deleteCallback, authUser }) {
    return (
        <Table celled unstackable striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{translateComp('user.mail', 'E-Mail')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('user.forname', 'Vorname')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('user.surname', 'Nachname')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('user.phone', 'Telefon')}</Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.edit', 'Bearbeiten')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.delete', 'Löschen')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('rights.rights', 'Rechte')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {!isEmpty(users) &&
                    users.map(user => {
                        return (
                            <UserTableRow
                                key={v1()}
                                rights={rights}
                                user={user}
                                submitUser={submitUser}
                                deleteCallback={deleteCallback}
                                authUser={authUser}
                            />
                        );
                })}
                {isEmpty(users) && (
                    <Table.Row>
                        <Table.Cell colSpan='7'>
                            {translateComp('table.noDataText', 'Currently no data')}
                        </Table.Cell>
                    </Table.Row>
                )}                    
            </Table.Body>
        </Table>
    );
}
