import React, { useState } from 'react';

import SimpleModalRemoteControlled from '../../../common/SimpleModalRemoteControlled';
import ModalActionButton from '../../../common/ModalActionButton';
import ModalStartButton from '../../../common/ModalStartButton';
import {  Form, Icon  } from 'semantic-ui-react';

import { translateComp, translateString } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';
import SecureInput from '../../../common/SecureInput';


import OperatorForm from './OperatorForm';
import * as operatorModel from '../../../../models/operator';
import { objectHasEmptyValues, isValidMailAddress } from '../../../../misc/utils';


export default function OperatorAndPasswordModal({ 
    disabled, 
    icon, 
    text, 
    buttonText, 
    submitCallback, 
    operator = operatorModel.operator 
}) {
    const [password, setPassword] = useState('');

    const [_operator, setOperator] = useState(operator);
    const [isValidForm, setIsValidForm] = useState(true);
    
    const [showOperatorModal, setShowOperatorModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const saveOperatorEvent = () => {
        if (objectHasEmptyValues(_operator) || !isValidMailAddress(_operator.mail)) {
            setIsValidForm(false);
        } else {
            setIsValidForm(true);
            setShowOperatorModal(false);
            setShowPasswordModal(true);
            // Wirklich gespeichert wird erst auf der zweiten Seite bei savePasswordEvent!        
        }
    }

    const cancelOperatorEvent = () => {
        setOperator(operator);
        setIsValidForm(true);
        setShowOperatorModal(false);
        setShowPasswordModal(false);
    }

    const savePasswordEvent = ()=>{
        setShowOperatorModal(false);
        setShowPasswordModal(false);
        const saveData = {..._operator, password};
        submitCallback(saveData);
    };

    const cancelPasswordEvent = ()=>{
        setShowOperatorModal(true);
        setShowPasswordModal(false);
    };

    const passwordHeaderText = translateString('operator.operatorPassword', {
        username: _operator.username,
    });
    return (
        <>
        { !showPasswordModal &&
        <SimpleModalRemoteControlled
            button={(props) => <ModalStartButton buttonIcon={icon} buttonText={buttonText} disabled={disabled} onOpen={()=>setShowOperatorModal(true)}/>}
            isOpen={showOperatorModal}
            openCallback={()=>setShowOperatorModal(true)}
            closeCallback={()=>setShowOperatorModal(false)}
            header={
                <div>
                    <Icon name={icon} />
                    {text}
                </div>
            }
            content={
                <OperatorForm
                    onChange={(key, val) => setOperator({ ..._operator, [key]: val })}
                    operator={_operator}
                    isValidForm={isValidForm}
                    setIsValidForm={setIsValidForm}
                />
            }
            actions={(props) => <ModalActionButton submitCallback={saveOperatorEvent} cancelCallback={cancelOperatorEvent} isValidForm={isValidForm} submitIcon='arrow right'
            submitText='common.next' />}
            />
        }  
        { showPasswordModal &&
            
            <SimpleModalRemoteControlled
                button={(props) => <ModalStartButton buttonIcon='unlock' buttonText={buttonText} disabled={disabled} onOpen={()=>setShowPasswordModal(true)}/>}
                isOpen={showPasswordModal}
                openCallback={()=>setShowPasswordModal(true)}
                closeCallback={()=>setShowPasswordModal(false)}
                header={
                    <div>
                        <Icon name='unlock' />
                        {passwordHeaderText}
                    </div>
                }
                content={
                    <>
                        <Form style={{ marginLeft: 30, marginRight: 30 }}>
                            <SecureInput
                                placeholder={password}
                                label={translateComp('operator.password', 'Passwort')}
                                changeCallback={val => setPassword(val)}
                                type='password'
                                required
                                handleKeyPress={val => setPassword(val)}
                                hasError={!isValidForm && isEmpty(password)}
                                errorMessage={translateString('operator.messages.mandatory_password')}
                            />
                        </Form>
                    </>
                }
                actions={(props) => <ModalActionButton submitCallback={savePasswordEvent} cancelCallback={cancelPasswordEvent} isValidForm={isValidForm} />}
            />

        }
    </>
);
}
