// Domain
export const OPERATORS_REQUEST = 'OPERATORS_REQUEST';
export const OPERATORS_COMMIT = 'OPERATORS_COMMIT';
export const OPERATORS_ROLLBACK = 'OPERATORS_ROLLBACK';
export const DEVICES_REQUEST = 'DEVICES_REQUEST';
export const DEVICES_COMMIT = 'DEVICES_COMMIT';
export const DEVICES_ROLLBACK = 'DEVICES_ROLLBACK';
export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_COMMIT = 'USERS_COMMIT';
export const USERS_ROLLBACK = 'USERS_ROLLBACK';
export const SET_LICENSEKEY = 'SET_LICENSEKEY';
export const LICENSE_REQUEST = 'LICENSE_REQUEST';
export const LICENSE_COMMIT = 'LICENSE_COMMIT';
export const LICENSE_ROLLBACK = 'LICENSE_ROLLBACK';
export const OPERATORDEVICES_REQUEST = 'OPERATORDEVICES_REQUEST';
export const OPERATORDEVICES_COMMIT = 'OPERATORDEVICES_COMMIT';
export const OPERATORDEVICES_ROLLBACK = 'OPERATORDEVICES_ROLLBACK';
export const MAPS_REQUEST = 'MAPS_REQUEST';
export const MAPS_COMMIT = 'MAPS_COMMIT';
export const MAPS_ROLLBACK = 'MAPS_ROLLBACK';

// App
export const SET_DASHBOARD_VIEW = 'SET_DASHBOARD_VIEW';
export const SET_SETTINGS = 'SET_SETTINGS';
export const DISPATCH_MESSAGE = 'DISPATCH_MESSAGE';

// USER
export const SET_SETUP = 'SET_SETUP';
export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_COMMIT = 'SIGNIN_COMMIT';
export const SIGNIN_ROLLBACK = 'SIGNIN_ROLLBACK';
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SET_TOKEN = 'SET_TOKEN';
