import React from 'react';
import { Form } from 'semantic-ui-react';

import { translateComp, translateString } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';
import SecureInput from '../../../common/SecureInput';

export default function PasswordForm({ passwordPair, setPasswordCallback, isValid }) {
    return (
        <div>
            <Form.Group widths='equal'>
                <SecureInput
                    placeholder={translateString('user.oldPassword', 'Altes Passwort')}
                    id='old'
                    disableAutoComplete
                    label={translateComp('user.oldPassword', 'Altes Passwort')}
                    changeCallback={val => {
                        setPasswordCallback({ ...passwordPair, old: val });
                    }}
                    value={passwordPair.old}
                    hasError={!isValid && isEmpty(passwordPair.old)}
                    errorMessage={translateString('user.messages.mandatory_password')}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <SecureInput
                    placeholder={translateString('user.newPassword', 'Neues Passwort')}
                    id='new'
                    disableAutoComplete
                    label={translateComp('user.newPassword', 'Neues Passwort')}
                    changeCallback={val => {
                        setPasswordCallback({ ...passwordPair, new: val });
                    }}
                    value={passwordPair.new}
                    hasError={!isValid && isEmpty(passwordPair.new)}
                    errorMessage={translateString('user.messages.mandatory_password')}
                />
            </Form.Group>
        </div>
    );
}
