import * as API from '../backend/requests/authRequests';
import * as types from './utils/actionTypes';
import { makeActionCreator } from './utils/actionCreator';
import { setDashboardView, sendMessage, sendErrorMessage, sendSuccessMessage } from './appActions';
import { TOASTIFY_SEVERITY } from '../misc/const';
import { DASHBOARD_DEFAULT } from '../misc/dashboardViews';
import * as Cookie from '../misc/helper/cookieHelper';
import { setTokenHeader } from '../backend/API';
import { translateString } from '../i18n/utils';

// Action Creator:
export const setSetup = makeActionCreator(types.SET_SETUP, 'hasSetup');
export const saveUser = makeActionCreator(types.SIGNIN_COMMIT, 'user', 'token');
export const resetUser = makeActionCreator(types.SIGNOUT_REQUEST);
export const setToken = makeActionCreator(types.SET_TOKEN, 'token');

/**
 * Redux Action fuer die Abfrage der Benutzerdaten
 * @param  {String} token Der API Token
 * @return {Function} Die Redux Action
 */
export function requestAuthData() {
    return {
        types: [types.SIGNIN_REQUEST, types.SIGNIN_COMMIT, types.SIGNIN_ROLLBACK],
        callApi: () => API.getAuthData(),
        onFailure: () => {
            sendMessage({
                text: 'messages.userNotReceived',
                severity: TOASTIFY_SEVERITY.ERROR,
            });
        },
    };
}

export function hasSetup() {
    return dispatch => {
        API.getSetupStatus()
            .then(({ data }) => {
                dispatch(setSetup(data.hasSetup));
            })
            .catch(error => {
                dispatch(sendErrorMessage(error));
            });
    };
}

/**
 * Redux Action fuer ein Setup
 * @export
 * @param {String} mail Der Benutzername
 * @param {String} password Das Passwort
 * @param {String} setupKey Der Key des Setups
 * @return {Object} Die Redux Action
 */
export function setup(mail, password, setupKey) {
    return dispatch => {
        // TODO das wird true gesetzt, auch wenn der Token nicht stimmt??
        API.postSetup(mail, password, setupKey)
            .then(() => {
                dispatch(setSetup(true));
                dispatch(sendSuccessMessage('messages.setupSuccessful'));
            })
            .catch(error => {
                dispatch(setSetup(false));
                dispatch(sendErrorMessage(error));
            });
    };
}

/**
 * Redux Action fuer die Anmeldung
 * @param  {String} username Der Benutzername
 * @param  {String} password Das Passwort
 * @return {Function} Die Redux Action
 */
export function login(username, password) {
    return dispatch => {
        API.postLogin(username, password)
            .then(({ data }) => {
                const { token } = data;

                if (token) {
                    setTokenHeader(token);
                    dispatch(setToken(token));
                    Cookie.set(token);
                    dispatch(requestAuthData());
                    dispatch(sendSuccessMessage('messages.loginSuccessful'));
                }
            })
            .catch(err => {
                let transportError = err;
                if (err.response.status === 401 && err.response.data.type === 'Unauthorized') {
                    const message = translateString('messages.loginFailed');
                    transportError = { message: message };
                } else if (err.response.data.message) {
                    transportError = { message: err.response.data.message };
                }
                dispatch(resetUser());
                dispatch(sendErrorMessage(transportError));
            });
    };
}

export function changePassword(oldPassword, newPassword) {
    return (dispatch, getState) => {
        const {
            userState: {
                authentication: { user },
            },
        } = getState();
        const passwords = {
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        API.postChangePassword(user._id, passwords)
            .then(() => {
                dispatch(sendSuccessMessage('messages.passwordChanged'));
            })
            .catch(err => {
                if (err.response.status === 403) {
                    dispatch(sendErrorMessage({ message: 'user.messages.error_passwordcheck' }));
                } else {
                    dispatch(sendErrorMessage(err));
                }
            });
    };
}

export function logout() {
    return dispatch => {
        Cookie.remove();
        dispatch(resetUser());
        dispatch(setDashboardView(DASHBOARD_DEFAULT));
    };
}
