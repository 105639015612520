/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { Modal } from 'semantic-ui-react';

export default function SimpleModalRemoteControlled({ button, isOpen, openCallback, closeCallback, header, content, actions, dimmer = 'blurring' }) {
    const OpenButton = button;
    const ActionButtons = actions;

    return (
        <Modal
            dimmer={dimmer}
            open={isOpen}
            onOpen={openCallback}
            onClose={closeCallback}
            trigger={<OpenButton onOpen={() => openCallback(true)} />}
        >
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>{content}</Modal.Content>
            <Modal.Actions>
                <ActionButtons />
            </Modal.Actions>
        </Modal>
    );
}
