import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';

import DeviceModal from './DeviceModal';
import ActiveImage from '../../../common/ActiveImage';
import DeleteConfirmDialog from '../../../common/DeleteConfirmDialog';

import { translateComp, translateString } from '../../../../i18n/utils';

export default function DeviceTableRow({ device, submitDevice, deleteCallback, isValid, rights }) {
    const [deviceData, setDeviceData] = useState(device);
    const deletable = device.active;
    const cancelCallbackHandler = () => {
        setDeviceData(device);
    };
    const deleteCallbackHandler = () => {
        deleteCallback(device);
    };

    const deleteMessage = translateString('messages.deleteDeviceMessage', {
        name: device.name,
        deviceId: device.deviceId,
    });

    return (
        <Table.Row>
            <Table.Cell>
                <ActiveImage active={device.active} />
            </Table.Cell>
            <Table.Cell>{device.deviceId}</Table.Cell>
            <Table.Cell>{device.name}</Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <DeviceModal
                    disabled={!rights.update}
                    icon='pencil'
                    text={translateComp('common.device', 'Device')}
                    submitCallback={() => {
                        submitDevice(deviceData);
                    }}
                    cancelCallback={cancelCallbackHandler}
                    onChange={(key, value) => {
                        setDeviceData({ ...deviceData, [key]: value });
                    }}
                    device={device}
                    deviceData={deviceData}
                    isValid={isValid}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <DeleteConfirmDialog
                    closeCallback={cancelCallbackHandler}
                    confirmCallback={deleteCallbackHandler}
                    header={translateString('device.delete')}
                    content={deleteMessage}
                    cancelButtonText={translateString('common.abort')}
                    confirmButtonText={translateString('common.ok')}
                    disabled={!rights.delete || deletable}
                />
            </Table.Cell>
        </Table.Row>
    );
}
