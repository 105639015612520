import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import '../../css/fallback.css';

/**
 * Einfache Komponent fuer ein Fallback beim Laden von Uebersetzungen
 */
function FallbackUI() {
    return (
        <div>
            <Dimmer active inverted>
                <Loader indeterminate>Loading...</Loader>
            </Dimmer>
        </div>
    );
}

export default FallbackUI;
