import React from 'react';
import { Form, Icon, TextArea } from 'semantic-ui-react';

import { translateString } from '../../i18n/utils';
import { isEmpty } from '../../misc/utils';

import '../../css/labelinputfield.css';

export default function LabelTextAreaField({ id, label, hasError = false, errorMessage, disabled = true, value, onChange, onBlur, placeholder, style, required }) {
    const errMessage = hasError ? { content: errorMessage } : false;
    return (
        <div className='field'>
        <Form.Field
            id={id}
            control={TextArea}
            label={
                <div>
                    {label}
                    {required && (
                        <Icon.Group>
                            <Icon name='asterisk' color='red' size='tiny' corner='top right' />
                        </Icon.Group>
                    )}
                </div>
            }            
            placeholder={isEmpty(placeholder) ? translateString('common.placeholder') : placeholder}
            error={errMessage}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur ? e => onBlur(e.target.value) : null}
            value={value}
            style={style}
            disabled={disabled}
        />
        {!hasError && <div className='ui pointing above prompt label messagespacer' role='alert' aria-atomic='true' />}
        </div>
    );
}
