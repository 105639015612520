import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';

function Nav(props) {
    return <NavLink exact='true' {...props} activeclassname='active' />;
}

/**
 * Gibt ein MenuItem fuer die Navigationsleiste zurueck
 * @export
 * @param {*} { icon, title, onClick, to = '/' }
 * @returns
 */
export default function MenuItem({ icon, title, to = '/', onClick, isVisible = true }) {
    if (!isVisible) return null;
    return (
        <Menu.Item as={Nav} to={to} onClick={onClick}>
            <Icon name={icon} />
            {title}
        </Menu.Item>
    );
}
