// @flow
import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

import { translateString } from '../../i18n/utils';

/**
 * Gibt einen Lade-Overlay zurueck
 *
 * @export
 * @param {*} { show }
 * @returns
 */
export default function Validating({ show }) {
    if (show)
        return (
            <Dimmer active inverted>
                <Loader inverted content={translateString('common.validating')} />
            </Dimmer>
        );
    return null;
}
