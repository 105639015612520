import { sendErrorMessage } from '../appActions';

import * as API from '../../backend/requests/operatorRequests';
import * as types from '../utils/actionTypes';
import { translateString } from '../../i18n/utils';

/**
 * Redux Action fuer die Abfrage der Operatordaten
 * @export
 * @returns {ThunkAction}
 */
export function requestOperatorData() {
    return {
        types: [types.OPERATORS_REQUEST, types.OPERATORS_COMMIT, types.OPERATORS_ROLLBACK],
        callApi: () => API.getOperators(),
    };
}

const operatorErrorhandling = (operator, dispatch, err) => {
    const EXCEEDED_LICENSES = 'exceeded_max_allowed_users_of_';
    const UPDATE_OR_CREATE_ERR = 'Error while creating/updating';
    const username = operator.username;
    const errRespDataMsg = err.response.data.message;
    let errMessage = errRespDataMsg? err.response.data.message : translateString('messages.backendErr');
    if (errRespDataMsg?.startsWith(EXCEEDED_LICENSES)) {
        const allowedNumberOperators = errRespDataMsg.replace(EXCEEDED_LICENSES, '');
        errMessage = translateString('operator.messages.exceeded_max_allowed_users_of', {
            maxOperators: allowedNumberOperators,
        });
    }
    else if (errRespDataMsg?.startsWith(UPDATE_OR_CREATE_ERR)) {
        errMessage = translateString('operator.messages.update_or_create_err', {
            username: username,
        });
    }
    dispatch(sendErrorMessage({ message: errMessage }));
}

export function addOperator(operator) {
    return dispatch => {
        API.postCreateOperator(operator)
            .then(() => {
                dispatch(requestOperatorData());
            })
            .catch(err => {
                operatorErrorhandling(operator, dispatch, err);
            });
    };
}

export function updateOperator(operator) {
    return dispatch => {
        API.postOperator(operator)
            .then(() => {
                dispatch(requestOperatorData());
            })
            .catch(err => {
                operatorErrorhandling(operator, dispatch, err);
            });
    };
}

export function changeOperatorPassword(operator, password) {
    return dispatch => {
        API.postNewOperatorPassword(operator, password)
            .then(() => {
                dispatch(requestOperatorData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
}

export function deleteOperator(operator) {
    return dispatch => {
        API.deleteOperator(operator._id)
            .then(() => {
                dispatch(requestOperatorData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
}
