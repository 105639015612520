/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable valid-typeof */
import get from 'lodash.get';

import BaseValidationElement from '../components/common/form/helper/BaseValidationElement';
import BaseDisableElement from '../components/common/form/helper/BaseDisableElement';

export const VARIABLE_TYPE = {
    NUMBER: 'number',
    STRING: 'string',
    BOOLEAN: 'boolean',
    OBJECT: 'object',
    UNDEFINED: 'undefined',
    FUNCTION: 'function',
};

export const VALIDATION_STATE = {
    NONE: null,
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
};

/**
 * Prüft, ob das erste Datum zeitlich hinter dem zweiten Datum liegt
 * @param {*} date1
 * @param {*} date2
 * @returns {Boolean}
 */
export const isExpired = (expireHours: Number, timestamp): Boolean => {
    const expireStamp = Math.floor(timestamp) + expireHours * 60 * 60 * 1000;
    const now = new Date().getTime();

    return now > expireStamp;
};

/**
 * Checks whether the value is a function
 *
 * @param {function} functionToCheck
 * @returns {boolean}
 */
export const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

/**
 * Checks if it is a String
 *
 * @param {String} s
 * @returns {boolean}
 */
export const isString = s => {
    return Object.prototype.toString.call(s) === '[object String]';
};

/**
 * Checks if it is an Array
 *
 * @param {Array} x
 * @returns {boolean}
 */
export const isArray = x => {
    return Array.isArray(x);
};

/**
 * Checks if the object contains the key
 *
 * @param {Object} object
 * @param {String} property
 * @returns {boolean}
 */
export const hasProperty = (object, property) => {
    let hasPropertyRes = false;
    if (object !== null) {
        if (typeof object === VARIABLE_TYPE.OBJECT) {
            if (object.hasOwnProperty(property)) {
                hasPropertyRes = true;
            }
        }
    }
    return hasPropertyRes;
};

/**
 * Used to retrieve the matches when matching a string against a regular expression. Returns an array with the matches or false if there are none.
 *
 * @param {*} pattern
 * @param {*} value
 * @returns {Array}
 */
export const matchRegex = (pattern, value) => {
    if (!pattern) return false;

    // string pattern, one validation rule
    if (typeof pattern === 'string') {
        const condition = new RegExp(pattern, 'i');
        return value.match(condition);
    }

    // array patterns, multiple validation rules
    if (isArray(pattern)) {
        const conditions = pattern.map(rule => new RegExp(rule, 'g'));
        return conditions.map(condition => value.match(condition));
    }

    return false;
};

/**
 * Prueft, ob die Variable einen Wert enthält
 * @param {*} value
 * @returns {boolean}
 */
export const isEmpty = (value): boolean => {
    if (value !== null) {
        switch (typeof value) {
            case VARIABLE_TYPE.UNDEFINED: {
                return true;
            }
            case VARIABLE_TYPE.NUMBER: {
                return false;
            }
            case VARIABLE_TYPE.STRING: {
                if (value === '') {
                    return true;
                }
                return false;
            }
            case VARIABLE_TYPE.BOOLEAN: {
                return false;
            }
            case VARIABLE_TYPE.OBJECT: {
                if (value.length <= 0) {
                    return true;
                }
                for (const key in value) {
                    if (value.hasOwnProperty(key)) {
                        if (value[key] !== null && value[key] !== '') return false;
                    }
                }

                return true;
            }
            default: {
                if (value.length > 0) {
                    return false;
                }
                return true;
            }
        }
    }
    return true;
};

/**
 * Executes the search for a match between a regular expression and a specified string. Returns true or false.
 *
 * @param {*} pattern
 * @param {*} value
 * @returns {boolean}
 */
export const testRegex = (pattern, value, flags = 'i') => {
    if (isEmpty(pattern)) return false;
    if (isEmpty(value)) return false;

    // string pattern, one validation rule
    if (isString(pattern)) {
        const condition = new RegExp(pattern, flags);
        return condition.test(value);
    }

    // array patterns, multiple validation rules
    if (isArray(pattern)) {
        const conditions = pattern.map(rule => new RegExp(rule, 'g'));
        return conditions.map(condition => condition.test(value));
    }

    return false;
};

export const removeSpaces = str => {
    if (!isEmpty(str)) return str.replace('/\\s+/g', '');
    return '';
};

/**
 * Checks if it is a valid phonenumber
 *
 * @param {*} number
 * @returns {boolean}
 */
export const isValidPhoneNumber = number => {
    return testRegex('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$', removeSpaces(number));
};

/**
 * Checks if it is a valid mail address
 *
 * @param {*} mail
 * @returns {boolean}
 */
export const isValidMailAddress = mail => {
    return testRegex(
        '(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))',
        removeSpaces(mail),
    );
};

/**
 * Checks if it is a valid username
 *
 * @param {*} characters
 * @returns {boolean}
 */
export const isValidUsername = characters => {
    return testRegex('^[a-zA-Z0-9_]{1,}[a-zA-Z]+[0-9]*$', removeSpaces(characters));
};

/**
 * Matches any kind of letter from any language
 *
 * @param {*} characters
 * @returns {boolean}
 */
export const isOnlyUnicode = characters => {
    return testRegex('^\\p{L}+$', removeSpaces(characters), 'u');
};

/**
 * Only matches ASCII alphabets (both lowercase and uppercase)
 *
 * @param {*} characters
 * @returns {boolean}
 */
export const isOnlyAscii = characters => {
    return testRegex('^[A-Za-z]+$', removeSpaces(characters));
};

/**
 * Checks if the Object contains key/values that are empty
 *
 * @param {*} obj
 * @returns {boolean}
 */
export const objectHasEmptyValues = (obj): boolean => {
    if (obj !== null) {
        if (obj.length <= 0) {
            return true;
        }
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (isEmpty(obj[key])) {
                    console.log(`FOR KEY ${key} VALUE IS EMPTY `);
                    return true;
                }
            }
        }

        return false;
    }
    return true;
};

/**
 * Validiert ein BaseValidationElement
 *
 * @param {Object} obj Objekt mit dem Wert des Feldes
 * @param {BaseDisableElement} f Das Feld
 * @param {boolean} [newObj=false] Ob es ein neues Objekt ist
 * @param {('error' | null)} [error=null] Wert, der als validationState bei Misserfolg gesetzt wird
 * @returns {boolean} Ob Das Feld valide ist
 */
function validateValidationElement(
    obj: Object,
    f: BaseValidationElement,
    newObj: boolean = false,
    error?: 'error' | null = null,
): boolean {
    let valid = f.isValid(obj, get(obj, f.accessor), newObj);

    if (typeof f.validationMethod === 'function' && f.validationMethod) {
        // $FlowFixMe
        valid = f.validationMethod(obj, get(obj, f.accessor), newObj);
        if (!valid) {
            f.validationState = error;
        }
    }

    if (f.required && !valid) {
        f.validationState = error;
        return valid;
    }

    return true;
}

/**
 * Validiert ein BaseDisableElement
 *
 * @param {Object} obj Objekt mit dem Wert des Feldes
 * @param {BaseDisableElement} f Das Feld
 * @param {boolean} [newObj=false] Ob es ein neues Objekt ist
 * @param {('success' | null)} [success=null] Wert, der als validationState bei Erfolg gesetzt wird
 * @param {('error' | null)} [error=null] Wert, der als validationState bei Misserfolg gesetzt wird
 * @returns {boolean} Ob Das Feld valide ist
 */
function validateDisableElement(
    obj: Object,
    f: BaseDisableElement,
    newObj: boolean = false,
    success?: 'success' | null = null,
    error?: 'error' | null = null,
): boolean {
    const disabled: boolean = f.isDisabled(obj, newObj);
    if (disabled) {
        return true;
    }

    return validateValidationElement(obj, f, newObj, success, error);
}

/**
 * Validiert das gegebene Feld
 *
 * @param {Object} obj Objekt mit dem Wert des Feldes
 * @param {Object} f Das Feld
 * @param {boolean} [newObj=false] Ob es ein neues Objekt ist
 * @param {String} [success=null] Wert, der als validationState bei Erfolg gesetzt wird
 * @param {String} [error=null] Wert, der als validationState bei Misserfolg gesetzt wird
 * @return {Boolean} Ob das Feld valide ist
 */
function validateField(
    obj: Object,
    f: BaseElement,
    newObj: boolean = false,
    success?: 'success' | null = null,
    error?: 'error' | null = null,
): boolean {
    let valid = true;
    if (f instanceof BaseValidationElement) {
        f.validationState = success;

        if (f instanceof BaseDisableElement) {
            valid = validateDisableElement(obj, f, newObj, success, error);
        } else {
            valid = validateValidationElement(obj, f, newObj, success, error);
        }
    }
    return valid;
}

/**
 * Validiert die gegebenen Felder
 * @export
 * @param {Array} fields Array mit den Feldern, die validiert werden
 * @param {Object} obj Objekt mit den Werten der Felder
 * @param {boolean} [newObj=false] Ob es ein neues Objekt ist
 * @param {String} [success=null] Wert, der als validationState bei Erfolg gesetzt wird
 * @param {String} [error=null] Wert, der als validationState bei Misserfolg gesetzt wird
 * @return {Boolean} Ob alle Felder valide sind
 */
export function validateFields(
    fields: Array<Object>,
    obj: Object,
    newObj: boolean = false,
    success?: 'success' | null = null,
    error?: 'error' | null = null,
): boolean {
    let valid = true;

    if (fields) {
        for (const f of fields) {
            if (!validateField(obj, f, newObj, success, error)) {
                valid = false;
            }
        }
    }

    return valid;
}

/**
 * Entfernt die Werte von dem Objekt. Die zu entfernenden Properties einfach als Argumente mitgeben.
 * @param  {Object} object Objekt mit den Properties
 * @return {Object}        Objekt mit den entfernten Properties
 */
export function removeProperty(object: Object, args: Array<string>): Object {
    if (object && args.length > 0) {
        args.forEach(arg => {
            delete object[arg];
        });
    }
    return object;
}

/**
 * Entfernt ein Element aus einem Array und gibt das Array zurueck
 * @param {Array} array     Array mit Elementen
 * @param {String} element  das Element was entfernt werden soll
 * @return {Array}          das neue Array
 */
export function removeArrayElement(array: Array<string>, element: string): Array<string> {
    return array.filter(e => e !== element);
}
