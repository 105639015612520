import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import '../../css/iconbutton.css';

/**
 * Button mit Icon, der beim Hovern durch Text ersetzt wird
 * @export
 * @param {*} {
 * 	onClick,
 * 	icon,
 * 	text,
 * 	disabled = false,
 * 	size,
 * 	negative = false,
 * 	positive = false,
 * }
 * @returns
 */
export default function AnimatedIconButton({
    onClick,
    icon,
    text,
    disabled = false,
    size,
    negative = false,
    positive = false,
    fluid = true,
    style,
}) {
    return (
        <Button
            fluid={fluid}
            animated='fade'
            onClick={onClick}
            disabled={disabled}
            size={size}
            negative={negative}
            positive={positive}
            style={style}
        >
            <Button.Content hidden>{text}</Button.Content>
            <Button.Content visible>
                <Icon name={icon} />
            </Button.Content>
        </Button>
    );
}
