import { setToken, requestAuthData, hasSetup } from '../authActions';
import { sendMessage, sendErrorMessage } from '../appActions';
import * as Cookie from '../../misc/helper/cookieHelper';
import { TOASTIFY_SEVERITY } from '../../misc/const';
import { getPing } from '../../backend/requests/connectionRequests';
import { getOperatorDeviceMappings } from '../../backend/requests/operatorRequests';
import * as types from '../utils/actionTypes';
import { setTokenHeader } from '../../backend/API';

export function getConnection() {
    return getPing()
        .then(result => {
            console.log(result);
        })
        .catch(error => {
            sendErrorMessage(error);
        });
}

/**
 * Redux Action fuer die Initialisierung
 * @return {Function} Die Redux Action
 */
export function init() {
    return dispatch => {
        dispatch(hasSetup());
        const token = Cookie.get();

        if (token) {
            setTokenHeader(token);
            dispatch(setToken(token));
            dispatch(requestAuthData());
        }
    };
}

/**
 * Redux Action fuer die Abfrage der Geraete- und Benutzerdaten
 * @return {Function} Die Redux Action
 */
export function requestOperatorDeviceData() {
    return {
        types: [types.OPERATORDEVICES_REQUEST, types.OPERATORDEVICES_COMMIT, types.OPERATORDEVICES_ROLLBACK],
        callApi: () => getOperatorDeviceMappings(),
        onFailure: () => {
            sendMessage({
                text: 'messages.devicesNotReceived',
                severity: TOASTIFY_SEVERITY.ERROR,
            });
        },
    };
}
