// @flow
import React from 'react';
import { Table } from 'semantic-ui-react';
import { v1 } from 'uuid';

import MapsTableRow from './MapsTableRow';

import { translateComp } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';

import '../../../../css/App.css';

export default function MapsTable({ mapsList, submitMapsCallback, deleteMapsCallback, checkMaps, changeDefaultCallback}) {
    return (
        <Table celled unstackable striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{translateComp('maps.name', 'Service Name')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('maps.url', 'Service Url')}</Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.defaulttoggle', 'Default')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.edit', 'Bearbeiten')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.delete', 'Löschen')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {!isEmpty(mapsList) &&
                    mapsList.map(maps => {
                        return (
                            <MapsTableRow
                                key={v1()}
                                maps={maps}
                                submitMaps={submitMapsCallback}
                                deleteCallback={deleteMapsCallback}
                                checkMaps={checkMaps}
                                changeDefaultCallback={changeDefaultCallback}
                            />
                        );
                })}
                {isEmpty(mapsList) &&
                    <Table.Row>
                        <Table.Cell colSpan='4'>{translateComp('table.noDataText', 'Currently no data')}</Table.Cell>
                    </Table.Row>
                }  
            </Table.Body>
        </Table>
    );
}
