import * as types from '../actions/utils/actionTypes';

const initialState = {
    hasSetup: true,
    authentication: {
        isFetching: false,
        lastUpdated: null,
        token: '',
        user: null,
    },
};

/**
 * Updatet den Store und wird durch authActions aufgerufen
 * @export
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
// eslint-disable-next-line default-param-last
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_SETUP:
            return {
                ...state,
                hasSetup: action.hasSetup,
            };
        case types.SIGNIN_REQUEST:
            return {
                ...state,
                authentication: {
                    isFetching: true,
                    lastUpdated: state.authentication.lastUpdated,
                    user: state.authentication.user,
                    token: state.authentication.token,
                },
            };
        case types.SIGNIN_COMMIT:
            return {
                ...state,
                authentication: {
                    isFetching: false,
                    lastUpdated: new Date(),
                    user: action.payload.data.user,
                    token: action.payload.config.headers['x-access-token'],
                },
            };
        case types.SIGNIN_ROLLBACK:
            return {
                ...state,
                authentication: {
                    isFetching: false,
                    lastUpdated: null,
                    user: null,
                    token: '',
                },
            };
        case types.SIGNOUT_REQUEST:
            return {
                ...state,
                authentication: {
                    isFetching: false,
                    lastUpdated: new Date(),
                    user: null,
                    token: '',
                },
            };
        case types.SET_TOKEN:
            return {
                ...state,
                authentication: {
                    isFetching: false,
                    lastUpdated: new Date(),
                    user: state.authentication.user,
                    token: action.token,
                },
            };
        default:
            return state;
    }
}
