import React from 'react';
import { Table } from 'semantic-ui-react';
import { v1 } from 'uuid';

import OperatorTableRow from './OperatorTableRow';

import { translateComp } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';

export default function OperatorTable({ rights, operators, submitOperator, deleteCallback, passwordCallback }) {
    return (
        <Table celled unstackable striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('operator.active', 'Active')}
                    </Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('operator.username', 'Benutzername')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('operator.forname', 'Vorname')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('operator.surname', 'Nachname')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('operator.mail', 'E-Mail')}</Table.HeaderCell>
                    <Table.HeaderCell>{translateComp('operator.phone', 'Telefon')}</Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.edit', 'Bearbeiten')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('common.delete', 'Löschen')}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{ textAlign: 'center' }}>
                        {translateComp('operator.password', 'Passwort')}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {!isEmpty(operators) &&
                    operators.map(operator => {
                        return (
                            <OperatorTableRow
                                key={v1()}
                                rights={rights}
                                operator={operator}
                                submitOperator={submitOperator}
                                deleteCallback={deleteCallback}
                                passwordCallback={passwordCallback}
                            />
                        );
                    })}
                    {isEmpty(operators) && (
                        <Table.Row>
                            <Table.Cell colSpan='9'>
                                {translateComp('table.noDataText', 'Currently no data')}
                            </Table.Cell>
                        </Table.Row>
                    )}       
            </Table.Body>
        </Table>
    );
}
