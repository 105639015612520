import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';

import LabelInputField from '../../../common/LabelInputField';

import { translateComp, translateString } from '../../../../i18n/utils';
import { isValidMailAddress, isEmpty } from '../../../../misc/utils';

export default function UserForm({ onChange, user }) {
    const [errorInMail, setErrorInMail] = useState(false);
    const [errorInForname, setErrorInForname] = useState(false);
    const [errorInSurname, setErrorInSurname] = useState(false);
    const [errorInPhone, setErrorInPhone] = useState(false);

    const validMailHandler = () => {
        setErrorInMail(!isValidMailAddress(user.mail));
    };
    const validFornameHandler = () => {
        setErrorInForname(isEmpty(user.forname));
    };
    const validSurnameHandler = () => {
        setErrorInSurname(isEmpty(user.surname));
    };
    const validPhoneHandler = () => {
        setErrorInPhone(isEmpty(user.phone));
    };

    const validateAllFields = () => {
        validMailHandler();
        validFornameHandler();
        validSurnameHandler();
        validPhoneHandler();
    };

    useEffect(() => {
        validateAllFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <div>
            <Form.Group widths='equal'>
                <LabelInputField
                    id='mail'
                    type='text'
                    label={translateComp('user.mail', 'E-Mail')}
                    onChange={val => onChange({ ...user, mail: val })}
                    onBlur={validMailHandler}
                    value={user.mail || ''}
                    hasError={errorInMail}
                    required
                    errorMessage={translateString('user.messages.mandatory_mail')}
                />
                <LabelInputField
                    id='phone'
                    type='text'
                    label={translateComp('user.phone', 'Telefon')}
                    onChange={val => onChange({ ...user, phone: val })}
                    onBlur={validPhoneHandler}
                    value={user.phone || ''}
                    hasError={errorInPhone}
                    required
                    errorMessage={translateString('user.messages.mandatory_phone')}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <LabelInputField
                    id='forname'
                    type='text'
                    label={translateComp('user.forname', 'Vorname')}
                    onChange={val => onChange({ ...user, forname: val })}
                    onBlur={validFornameHandler}
                    value={user.forname || ''}
                    hasError={errorInForname}
                    required
                    errorMessage={translateString('user.messages.mandatory_forname')}
                />
                <LabelInputField
                    id='surname'
                    type='text'
                    label={translateComp('user.surname', 'Nachname')}
                    onChange={val => onChange({ ...user, surname: val })}
                    onBlur={validSurnameHandler}
                    value={user.surname || ''}
                    hasError={errorInSurname}
                    required
                    errorMessage={translateString('user.messages.mandatory_surname')}
                />
            </Form.Group>
        </div>
    );
}
