/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';

export default function SimpleModal({ button, header, content, actions, dimmer = 'blurring', isOpen }) {
    const OpenButton = button;
    const ActionButtons = actions;

    const [open, setOpen] = useState(isOpen);

    return (
        <Modal
            dimmer={dimmer}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            trigger={<OpenButton onOpen={() => setOpen(true)} />}
        >
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>{content}</Modal.Content>
            <Modal.Actions>
                <ActionButtons onAction={_open => setOpen(_open)} />
            </Modal.Actions>
        </Modal>
    );
}
