import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';

import LabelInputField from '../../../common/LabelInputField';

import { translateComp, translateString } from '../../../../i18n/utils';
import { isValidMailAddress, isEmpty, isValidUsername } from '../../../../misc/utils';
import HintBox from '../../../common/HintBox';

export default function OperatorForm({ onChange, operator, isValidForm, setIsValidForm }) {
    const [errorUsername, setErrorUsername] = useState(false);
    const [errorMail, setErrorMail] = useState(false);
    const [errorForname, setErrorForname] = useState(false);
    const [errorSurname, setErrorSurname] = useState(false);
    const [errorPhone, setErrorPhone] = useState(false);

    const validUsernameHandler = () => {
        setErrorUsername(!isValidUsername(operator.username));
    };
    const validMailHandler = () => {
        setErrorMail(!isValidMailAddress(operator.mail));
    };
    const validFornameHandler = () => {
        setErrorForname(isEmpty(operator.forname));
    };
    const validSurnameHandler = () => {
        setErrorSurname(isEmpty(operator.surname));
    };
    const validPhoneHandler = () => {
        setErrorPhone(isEmpty(operator.phone));
    };

    useEffect(() => {
        if (!isValidForm) {
            validUsernameHandler();
            validMailHandler();
            validFornameHandler();
            validSurnameHandler();
            validPhoneHandler();
            setIsValidForm(errorUsername && errorMail && errorForname && errorSurname && errorPhone);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValidForm, operator]);

    return (
        <Form>
            <Form.Group widths='equal'>
                <LabelInputField
                    id='username'
                    type='text'
                    label={translateComp('operator.username', 'Benutzername')}
                    onChange={val => onChange('username', val)}
                    onBlur={validUsernameHandler}
                    value={operator.username || ''}
                    hasError={errorUsername}
                    required
                    errorMessage={translateString('operator.messages.mandatory_username')}
                />
                <LabelInputField
                    id='mail'
                    type='text'
                    label={translateComp('operator.mail', 'E-Mail')}
                    onChange={val => onChange('mail', val)}
                    onBlur={validMailHandler}
                    value={operator.mail || ''}
                    hasError={errorMail}
                    required
                    errorMessage={translateString('operator.messages.mandatory_mail')}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <LabelInputField
                    id='forname'
                    type='text'
                    label={translateComp('operator.forname', 'Vorname')}
                    onChange={val => onChange('forname', val)}
                    onBlur={validFornameHandler}
                    value={operator.forname || ''}
                    hasError={errorForname}
                    required
                    errorMessage={translateString('operator.messages.mandatory_forname')}
                />
                <LabelInputField
                    id='surname'
                    type='text'
                    label={translateComp('operator.surname', 'Nachname')}
                    onChange={val => onChange('surname', val)}
                    onBlur={validSurnameHandler}
                    value={operator.surname || ''}
                    hasError={errorSurname}
                    required
                    errorMessage={translateString('operator.messages.mandatory_surname')}
                />
                <LabelInputField
                    id='phone'
                    type='text'
                    label={translateComp('operator.phone', 'Telefon')}
                    onChange={val => onChange('phone', val)}
                    onBlur={validPhoneHandler}
                    value={operator.phone || ''}
                    hasError={errorPhone}
                    required
                    errorMessage={translateString('operator.messages.mandatory_phone')}
                />
            </Form.Group>
            <HintBox
                show={isEmpty(operator._id)}
                title={translateComp('common.hint', 'hint')}
                message={translateComp('operator.messages.hint_password', 'hint_password')}
            />
        </Form>
    );
}
