import { get, post } from '../API';
import { WHOAMI, SETUP, AUTH, USER } from '../paths';

export function getAuthData() {
    return get(WHOAMI);
}

export function getSetupStatus() {
    return get(SETUP);
}

export function postSetup(mail, password, setupKey) {
    return post(SETUP, {
        mail: mail,
        password: password,
        key: setupKey,
    });
}

export function postLogin(mail, password) {
    return post(AUTH, {
        mail: mail,
        password: password,
    });
}

export function postChangePassword(userId, passwords) {
    return post(`${USER}/${userId}/password`, passwords);
}
