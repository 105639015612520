import { sendErrorMessage } from '../appActions';

import * as API from '../../backend/requests/userRequests';
import * as types from '../utils/actionTypes';
import { translateString } from '../../i18n/utils';

/**
 * Redux Action fuer die Abfrage der Benutzerdaten
 * @return {Function} Die Redux Action
 */
export function requestUserData() {
    return {
        types: [types.USERS_REQUEST, types.USERS_COMMIT, types.USERS_ROLLBACK],
        callApi: () => API.getUsers(),
    };
}
const userErrorhandling = (user, dispatch, err) => {
    const UPDATE_OR_CREATE_ERR = 'Error while creating/updating';
    const email = user.mail;
    const errRespDataMsg = err.response.data.message;
    let errMessage = errRespDataMsg? errRespDataMsg : translateString('messages.backendErr');
    if (errRespDataMsg?.startsWith(UPDATE_OR_CREATE_ERR)) {
        errMessage = translateString('user.messages.update_or_create_err', {
            email: email,
        });
    }
    dispatch(sendErrorMessage({ message: errMessage }));
}
export function addUser(user) {
    return dispatch => {
        API.postCreateUser(user)
            .then(() => {
                dispatch(requestUserData());
            })
            .catch(err => {
                userErrorhandling(user, dispatch, err);
            });
    };
}

export function updateUser(user) {
    return dispatch => {
        API.postUser(user)
            .then(() => {
                dispatch(requestUserData());
            })
            .catch(err => {
                userErrorhandling(user, dispatch, err);
            });
    };
}

export function deleteUser(user) {
    return dispatch => {
        API.deleteUser(user._id)
            .then(() => {
                dispatch(requestUserData());
            })
            .catch(err => {
                dispatch(sendErrorMessage(err));
            });
    };
}
