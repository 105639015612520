/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import Navigation from './Navigation';

import AuthContainer from '../../auth/AuthContainer';
import UserDashboard from '../modules/users/UserDashboard';
import LicenseDashboard from '../modules/license/LicenseDashboard';
import UserInfoDashboard from '../modules/settings/UserInfoDashboard';
import OperatorDashboard from '../modules/operators/OperatorDashboard';
import DeviceDashboard from '../modules/devices/DeviceDashboard';
import DashboardMainModule from '../modules/home/DashboardMainModule';
import MapsDashboard from '../modules/maps/MapsDashboard';

import { isExpired } from '../../../misc/utils';
import * as Cookie from '../../../misc/helper/cookieHelper';

/**
 * Implementiert die Navigationsleiste
 * Stellt die entsprechenden Routen bereit
 * @export
 * @param {*} props
 * @returns
 */
export default function RouterContainer(props) {
    const {
        logout,
        userState: {
            authentication: { user },
        },
        appState: { settings },
    } = props;
    const { token } = props;

    const [userData, setUserData] = useState(user);

    useEffect(() => {
        setUserData(user);
    }, [user]);

    const tsp = Cookie.get('tsp');
    if (isExpired(1, tsp)) {
        logout();
    }

    const updateUserHandler = updatedUser => {
        setUserData(updatedUser);
    };

    return (
        <BrowserRouter>
            <Grid>
                <Grid.Row>
                    <Navigation logoutCallback={logout} user={userData} token={token} />
                </Grid.Row>
                <Grid.Row className='navcontent' centered>
                    <Routes>
                        <Route path='/login' element={<AuthContainer {...props} />} />
                        <Route exact path='/' element={<DashboardMainModule {...props} />} />
                        <Route path='/users' element={<UserDashboard {...props} />} />
                        <Route path='/operators' element={<OperatorDashboard {...props} />} />
                        <Route path='/devices' element={<DeviceDashboard {...props} />} />
                        <Route path='/license' element={<LicenseDashboard {...props} />} />
                        <Route path='/maps' element={<MapsDashboard {...props} />} />
                        <Route
                            path='/settings'
                            element={
                                <UserInfoDashboard
                                    settings={settings}
                                    user={userData}
                                    updateUserCallback={updateUserHandler}
                                    {...props}
                                />
                            }
                        />
                        <Route exact path='/logout' element={<Navigate to='/login' />} />
                        <Route path='*' element={<Navigate to='/' />} />
                    </Routes>
                </Grid.Row>
            </Grid>
        </BrowserRouter>
    );
}
