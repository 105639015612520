import React from 'react';
import { Menu, Image } from 'semantic-ui-react';

import MenuItem from './MenuItem';
import { translateString } from '../../../i18n/utils';

import ibak from '../../../images/ibakLogoLight.png';
import { isEmpty } from '../../../misc/utils';

import '../../../css/semanticnavigation.css';

/**
 * Gibt eine Navigationsleiste zurueck
 * Implementiert Routen
 * @param {*} { logoutCallback }
 * @returns
 */
function Navigation({ token, user, logoutCallback }) {

    const width = window.innerWidth;
    // Furchtbarer Hack, es muss etwas geschehen, damit die Seite responsiver wird
    const widthBreakpoint = 1470;
    const labelActiveInfo = width >= widthBreakpoint ? translateString('common.operatorDevice') : '';
    const labelLogo = width >= widthBreakpoint ? 'IMAPP Administration' : 'IMAPP';
    const labelAdmin = width >= widthBreakpoint ? translateString('navigation.users') : '';
    const labelOperator = width >= widthBreakpoint ? translateString('navigation.operators') : '';
    const labelDevices = width >= widthBreakpoint ? translateString('navigation.devices') : '';
    const labelMaps = width >= widthBreakpoint ? translateString('navigation.maps') : '';
    const labelLicenses = width >= widthBreakpoint ? translateString('navigation.license') : '';
    const labelLoggedinUser = width >= widthBreakpoint ? `${user?.forname} ${user?.surname}` : '';
    const labelLogout = width >= widthBreakpoint ? translateString('navigation.logout') : '';
    return (
        <Menu fixed='top' size='large' stackable>
            <Menu.Item header>
                <Image className='ibakImage' src={ibak} alt='IBAK' size='small' />
                {labelLogo}
            </Menu.Item>
            <MenuItem icon='rss' title={labelActiveInfo} to='/' isVisible={!isEmpty(token)} />
            {user && user.user.read && (
                <MenuItem icon='users' title={labelAdmin} to='/users' isVisible={!isEmpty(token)} />
            )}
            {user && user.operator.read && (
                <MenuItem
                    icon='briefcase'
                    title={labelOperator}
                    to='/operators'
                    isVisible={!isEmpty(token)}
                />
            )}
            {user && user.device.read && (
                <MenuItem
                    icon='tablet alternate'
                    title={labelDevices}
                    to='/devices'
                    isVisible={!isEmpty(token)}
                />
            )}
            <MenuItem
                    icon='map'
                    title={labelMaps}
                    to='/maps'
                    isVisible={!isEmpty(token)}
            />
            {user && !isEmpty(token) && (
                <Menu.Menu position='right'>
                    <MenuItem icon='key' title={labelLicenses} to='/license' />
                    <MenuItem icon='user' title={labelLoggedinUser} to='/settings' />
                    <MenuItem
                        icon='sign-out'
                        title={labelLogout}
                        to='/logout'
                        onClick={logoutCallback}
                    />
                </Menu.Menu>
            )}
        </Menu>
    );
}

export default Navigation;
