import React, { useEffect, useState } from 'react';

import AdvancedModal from '../../../common/AdvancedModal';
import OperatorForm from './OperatorForm';
import * as operatorModel from '../../../../models/operator';
import { objectHasEmptyValues, isValidMailAddress } from '../../../../misc/utils';

export default function OperatorModal({ disabled, icon, text, buttonText, submitCallback, operator = operatorModel.operator }) {
    const [_operator, setOperator] = useState(operator);
    const [isValidForm, setIsValidForm] = useState(true);
    const [shouldCloseModal, setCloseModal] = useState(false);
    useEffect(() => {}, [_operator]);
    return (
        <AdvancedModal
            disabled={disabled}
            headerIcon={icon}
            headerText={text}
            buttonIcon={icon}
            buttonText={buttonText}
            open={!shouldCloseModal}
            isValidForm={isValidForm}
            submitCallback={() => {
                if (objectHasEmptyValues(_operator) || !isValidMailAddress(_operator.mail)) {
                    setIsValidForm(false);
                } else {
                    setIsValidForm(true);
                    setCloseModal(true);
                    submitCallback(_operator);
                }
            }}
            cancelCallback={() => {
                setOperator(operator);
                setIsValidForm(true);
            }}
        >
            <OperatorForm
                onChange={(key, val) => setOperator({ ..._operator, [key]: val })}
                operator={_operator}
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
            />
        </AdvancedModal>
    );
}
