import React from 'react';
import { Checkbox } from 'semantic-ui-react';

/**
 * Erzeugt eine Checkbox in Form eines Buttons
 * @param {boolean} disabled Ob die Checkbox deaktiviert ist
 * @param {boolean} checked Ob die Checkbox gechecked ist
 * @param {(SyntheticInputEvent<>) => mixed} onClick Callback fuer den Klick
 */
function Toggle({ disabled, checked, onClick }: Props) {
    if (disabled) {
        return <Checkbox toggle checked={checked} disabled={disabled} style={{ color: 'green' }} />;
    }

    return <Checkbox toggle checked={checked} onClick={onClick} style={{ color: 'green' }} />;
}

export default Toggle;
