import React, { Component } from 'react';
import { Form, Divider } from 'semantic-ui-react';
import CustomPanel from '../../../common/CustomPanel';

import LabelInputField from '../../../common/LabelInputField';
import Progressbar from '../../../common/Progressbar';

import { translateComp } from '../../../../i18n/utils';

/**
 * Klasse fuer einen Dashboard von Lizenzen
 *
 * @export
 * @class LicenseDashboard
 * @extends {Component}
 */
export default class LicenseDashboard extends Component {
    UNSAFE_componentWillMount() {
        const { requestLicenseKey } = this.props;

        requestLicenseKey();
    }

    render() {
        const {
            domainState: {
                // eslint-disable-next-line no-unused-vars
                license: { data = {}, key = '', isFetching },
            },
        } = this.props;

        return (
            <CustomPanel
                title={translateComp('common.license', 'Lizenz')}
                loader={isFetching}
                description={translateComp(
                    'license.description',
                    'Lizenzdetails zeigen die Anzahl der möglichen Benutzer die die App auf dem mobilen Gerät verwenden dürfen',
                )}
            >
                <Form>
                    <Progressbar
                        id='operatorProgress'
                        label={translateComp('license.operators', 'Operators')}
                        now={data.nowOperators}
                        max={data.maxOperators}
                    />

                    <Divider section hidden />
                    <Form.Group widths='equal'>
                        <LabelInputField
                            id='expirydate'
                            type='text'
                            label={translateComp('license.expiryDate', 'Ablaufdatum')}
                            disabled
                            value={new Date(data.expiryDate).toLocaleDateString()}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <LabelInputField
                            id='hostname'
                            type='text'
                            label={translateComp('license.hostname', 'Hostname')}
                            disabled
                            value={data.host}
                        />
                    </Form.Group>
                </Form>
            </CustomPanel>
        );
    }
}
