import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import 'semantic-ui-css/semantic.min.css';
import '../css/App.css';

import { actions } from '../actions/combindedActions';

// import AuthContainer from './auth/AuthContainer';
import RegisterContainer from './register/RegisterContainer';
import RouteContainer from './dashboard/router/RouterContainer';

// import { isEmpty } from '../misc/utils';

/**
 * Startpunkt der Anwendung
 */
function App(props) {
    const {
        userState: {
            hasSetup,
            authentication: { token },
        },
    } = props;

    const renderRegister = () => {
        return <RegisterContainer {...props} />;
    };

    /**
     * Rendert das Dashboard
     * @return {JSX} Das Dashboard
     */
    const renderDashboard = newToken => {
        const newProps = { ...props, token: newToken };
        return <RouteContainer {...newProps} />;
    };

    return (
        <div>
            {!hasSetup ? renderRegister() : null}
            {hasSetup ? renderDashboard(token) : null}
            <ToastContainer position='top-center'/>
        </div>
    );
}

const mapStateToProps = state => ({
    domainState: state.domainState,
    userState: state.userState,
    appState: state.appState,
});

/**
 * Verbindung der Komponente mit dem ReduxStore
 */
const ReduxApp = connect(mapStateToProps, actions)(App);

export default withTranslation()(ReduxApp);
