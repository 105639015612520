// import React, {useState} from 'react';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';


import { translateComp, translateString } from '../../i18n/utils';
import { COLOR_SET } from '../../misc/const';

const ModalActionButton = ({  cancelCallback, submitCallback, open = false, isValidForm, submitIcon='save', submitText='common.save' }) => {

    return (
        <div>
            <Button
                style={{ backgroundColor: COLOR_SET.SECONDARY }}
                content={translateComp('common.abort', 'Abbrechen')}
                onClick={() => {
                    cancelCallback();
                }}
            />
            <Button
                icon
                color='blue'
                style={{ backgroundColor: COLOR_SET.PRIMARY }}
                labelPosition='left'
                onClick={() => {
                    if (isValidForm) {
                        submitCallback();
                    }
                }}
            >
                <Icon name={submitIcon} />
                {translateString(submitText)}
            </Button>
        </div>
    );

};

export default ModalActionButton;