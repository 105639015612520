import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

import LabelInputField from '../../../common/LabelInputField';

import { translateComp, translateString } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';

export default function DeviceForm({ onChange, device, isValidForm }) {
    const [deviceName, setDeviceName] = useState(device.name);
    const [deviceNameIsValid, setDeviceNameIsValid] = useState(isValidForm);

    useEffect(() => {
        setDeviceNameIsValid(!isEmpty(deviceName));
    }, [deviceName]);

    return (
        <Form>
            <Form.Group>
                <LabelInputField
                    id='deviceid'
                    type='text'
                    label={translateComp('device.deviceid', 'Geräte-ID')}
                    disabled
                    value={device.deviceId}
                />
                <LabelInputField
                    id='name'
                    type='text'
                    label={translateComp('device.name', 'Name')}
                    onChange={val => {
                        setDeviceName(val);
                    }}
                    onBlur={val => {
                        onChange('name', val);
                    }}
                    value={deviceName || ''}
                    hasError={!deviceNameIsValid}
                    required
                    errorMessage={translateString('device.messages.mandatory_name')}
                />
            </Form.Group>
        </Form>
    );
}
