import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';

import LabelInputField from '../../../common/LabelInputField';

import { translateComp, translateString } from '../../../../i18n/utils';
import { isEmpty, isValidMailAddress } from '../../../../misc/utils';
import HintBox from '../../../common/HintBox';
import SecureInput from '../../../common/SecureInput';

export default function UserForm({ onChange, user, isValidForm, setIsValidForm }) {
    const [errorInMail, setErrorInMail] = useState(false);
    const [errorInPassword, setErrorInPassword] = useState(false);
    const [errorInForname, setErrorInForname] = useState(false);
    const [errorInSurname, setErrorInSurname] = useState(false);
    const [errorInPhone, setErrorInPhone] = useState(false);
    const validMailHandler = () => {
        setErrorInMail(!isValidMailAddress(user.mail));
    };
    const validPasswordHandler = () => {
        if (isEmpty(user._id)) setErrorInPassword(isEmpty(user.password));
    };
    const validFornameHandler = () => {
        setErrorInForname(isEmpty(user.forname));
    };
    const validSurnameHandler = () => {
        setErrorInSurname(isEmpty(user.surname));
    };
    const validPhoneHandler = () => {
        setErrorInPhone(isEmpty(user.phone));
    };

    useEffect(() => {
        if (!isValidForm) {
            validMailHandler();
            validPasswordHandler();
            validFornameHandler();
            validSurnameHandler();
            validPhoneHandler();
            setIsValidForm(errorInMail && errorInPassword && errorInForname && errorInSurname && errorInPhone);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValidForm, user]);
    return (
        <Form>
            <Form.Group widths='equal'>
                <LabelInputField
                    id='mail'
                    type='text'
                    label={translateComp('user.mail', 'E-Mail')}
                    onChange={val => onChange('mail', val)}
                    onBlur={validMailHandler}
                    value={user.mail || ''}
                    hasError={errorInMail}
                    required
                    errorMessage={translateString('user.messages.mandatory_mail')}
                />
                <SecureInput
                    placeholder={translateString('user.password', 'Passwort')}
                    id='password'
                    disableAutoComplete
                    label={translateComp('user.password', 'Passwort')}
                    changeCallback={val => onChange('password', val)}
                    onBlur={validPasswordHandler}
                    value={user.password || ''}
                    hasError={errorInPassword}
                    required={isEmpty(user._id) ? 'required' : false}
                    errorMessage={translateString('user.messages.mandatory_password')}
                />
            </Form.Group>
            <Form.Group widths='equal'>
                <LabelInputField
                    id='forname'
                    type='text'
                    label={translateComp('user.forname', 'Vorname')}
                    onChange={val => onChange('forname', val)}
                    onBlur={validFornameHandler}
                    value={user.forname || ''}
                    hasError={errorInForname}
                    required
                    errorMessage={translateString('user.messages.mandatory_forname')}
                />
                <LabelInputField
                    id='surname'
                    type='text'
                    label={translateComp('user.surname', 'Nachname')}
                    onChange={val => onChange('surname', val)}
                    onBlur={validSurnameHandler}
                    value={user.surname || ''}
                    hasError={errorInSurname}
                    required
                    errorMessage={translateString('user.messages.mandatory_surname')}
                />
                <LabelInputField
                    id='phone'
                    type='text'
                    label={translateComp('user.phone', 'Telefon')}
                    onChange={val => onChange('phone', val)}
                    onBlur={validPhoneHandler}
                    value={user.phone || ''}
                    hasError={errorInPhone}
                    required
                    errorMessage={translateString('user.messages.mandatory_phone')}
                />
            </Form.Group>
            <HintBox
                show={isEmpty(user._id)}
                title={translateComp('common.hint', 'hint')}
                message={translateComp('user.messages.hint_rights', 'hint_rights')}
            />
        </Form>
    );
}
