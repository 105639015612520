import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Navigate } from 'react-router-dom';

import Login from './Login';

import { translateComp, translateString } from '../../i18n/utils';

/**
 * Kontainer für das Login der die Authentifizierung regelt
 *
 */
export default function AuthContainer(props) {
    // export default class AuthContainer extends Component<Props, State> {
    /**
     * Fuehrt einen Anmeldeversuch durch
     * @param  {String} username Der Benutzername
     * @param  {String} password Das Passwort
     */
    const handleLogin = (username: string, password: string): void => {
        const { login } = props;

        login(username, password);
    };

    const { token } = props;
    if (token) {
        return <Navigate to='/' />;
    }

    const renderLogin = (): void => {
        const username: string = translateString('common.mail');
        const password: string = translateString('common.password');

        return (
            <Login
                title={translateComp('common.login', 'Anmelden')}
                username={username}
                password={password}
                button={translateComp('common.login', 'Anmelden')}
                passwordButton={translateComp('common.forgotPassword', 'Passwort vergessen')}
                callback={handleLogin}
            />
        );
    };

    return (
        <div>
            <Grid centered>
                <Grid.Row verticalAlign='middle'>
                    <Grid.Column style={{ marginTop: 50 }}>{renderLogin()}</Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}
