import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

import AdvancedModal from '../../../common/AdvancedModal';

import { translateComp, translateString } from '../../../../i18n/utils';
import { isEmpty } from '../../../../misc/utils';
import SecureInput from '../../../common/SecureInput';

export default function OperatorPasswordModal({ disabled, submitCallback, operatorName }) {
    const [password, setPassword] = useState('');
    const [isValidForm, setIsValidForm] = useState(true);
    const [shouldCloseModal, setCloseModal] = useState(false);

    const operatorHeaderText = translateString('operator.changePassword', {
        username: operatorName,
    });
    return (
        <AdvancedModal
            disabled={disabled}
            headerIcon='unlock'
            headerText={operatorHeaderText}
            buttonIcon='unlock'
            open={!shouldCloseModal}
            submitCallback={() => {
                if (isEmpty(password)) {
                    setIsValidForm(false);
                } else {
                    setIsValidForm(true);
                    setCloseModal(true);
                    submitCallback(password);
                    setPassword('');
                }
            }}
            isValidForm={isValidForm}
            cancelCallback={() => {
                setPassword('');
            }}
        >
            <Form style={{ marginLeft: 30, marginRight: 30 }}>
                <SecureInput
                    placeholder={password}
                    label={translateComp('operator.password', 'Passwort')}
                    changeCallback={val => setPassword(val)}
                    type='password'
                    required
                    handleKeyPress={val => setPassword(val)}
                    hasError={!isValidForm && isEmpty(password)}
                    errorMessage={translateString('operator.messages.mandatory_password')}
                />
            </Form>
        </AdvancedModal>
    );
}
