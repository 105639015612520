/* eslint-disable no-prototype-builtins */
import * as types from './utils/actionTypes';
import { makeActionCreator } from './utils/actionCreator';
import { isEmpty } from '../misc/utils';
import { TOASTIFY_SEVERITY } from '../misc/const';
import i18n from '../i18n/i18n';

// Action Creator:

/**
 * Sendet eine Action an den Reducer, dass die View des Dashboards wechselt
 * @export
 * @param {*} hasError
 * @returns
 */
export const setDashboardView = makeActionCreator(types.SET_DASHBOARD_VIEW, 'view');

export const setSettings = makeActionCreator(types.SET_SETTINGS, 'settings');

/**
 * Redux Action, die eine Nachricht ausloest
 * @param  {String} text     Text oder JSX der Nachricht
 * @param  {String} severity Schwere der Nachricht
 * @param  {Object} position Position der Nachricht. Hilfe: react-toastify
 * @return {Object}          Die Redux Action
 */
export function sendMessage({ text, severity, position }) {
    return {
        type: types.DISPATCH_MESSAGE,
        text,
        severity,
        position,
    };
}

export function sendSuccessMessage(message) {
    return dispatch => {
        dispatch(
            sendMessage({
                text: message,
                severity: TOASTIFY_SEVERITY.SUCCESS,
            }),
        );
    };
}

export function sendErrorMessage({ message }) {
    return dispatch => {
        let _message = message;

        if (isEmpty(message)) {
            _message = 'messages.unknown';
        }

        dispatch(
            sendMessage({
                text: _message,
                severity: TOASTIFY_SEVERITY.ERROR,
            }),
        );
    };
}

export function updateSettings(settings) {
    return (dispatch, getState) => {
        const {
            appState: { settings: current },
        } = getState();

        if (settings.lang !== current.lang) {
            i18n.changeLanguage(settings.lang);
        }

        dispatch(setSettings(settings));
    };
}
