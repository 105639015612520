import React, { useState } from 'react';

import AdvancedModal from '../../../common/AdvancedModal';
import RightsTable from './RightsTable';
import * as userModel from '../../../../models/user';

import { translateString } from '../../../../i18n/utils';
import { isFunction } from '../../../../misc/utils';

export default function RightsModal(props) {
    const  { disabled, submitDisabled, submitCallback, cancelCallback, onClick, user = userModel.user, authUser, buttonStyle, open, onOpen} = props;
    const [_user, setUser] = useState(user);

    const headerText = `${translateString('rights.rights', 'Rights')}: ${user.mail}`;

    return (
        <AdvancedModal
            disabled={disabled}
            submitDisabled={submitDisabled}
            headerIcon='unlock'
            headerText={headerText}
            buttonIcon='unlock'
            buttonStyle={buttonStyle}
            submitCallback={() => submitCallback(_user)}
            cancelCallback={() => isFunction(cancelCallback)? cancelCallback(): setUser(user)}
            onClick={onClick}
            isValidForm
            open={open}
            onOpen={onOpen}
        >
            <RightsTable
                user={_user}
                authUser={authUser}
                callback={(key, right, val) => setUser({ ..._user, [key]: { ..._user[key], [right]: val } })}
            />
        </AdvancedModal>
    );
}
