import { sendErrorMessage } from '../actions/appActions';
import { translateString } from '../i18n/utils';
import { matchRegex, isEmpty, hasProperty, isFunction, isArray } from '../misc/utils';

/**
 * Checks if types contains 3 actions
 *
 * @param {*} types
 * @returns
 */
const isArrayOfTypes = types => {
    return isArray(types) && types.length === 3 && types.every(type => typeof type === 'string');
};

/**
 * Checks if the first action is a request action
 *
 * @param {*} types
 * @returns
 */
const hasRequestType = types => {
    return matchRegex('(REQUEST)+$', types[0]);
};

/**
 * Checks if all conditions are met before making the api call
 *
 * @param {*} getState
 * @param {*} action
 * @returns
 */
const matchPreConditions = (getState, action) => {
    let isValid = true;

    if (!hasProperty(action, 'types')) {
        isValid = false;
    } else if (isEmpty(action.types)) {
        isValid = false;
    } else if (!isArrayOfTypes(action.types)) {
        isValid = false;
    } else if (!hasRequestType(action.types)) {
        isValid = false;
    } else if (hasProperty(action, 'shouldCallApi')) {
        if (isFunction(action.shouldCallApi)) {
            if (!action.shouldCallApi(getState())) {
                isValid = false;
            }
        }
    } else if (!isFunction(action.callApi)) {
        isValid = false;
    }

    return isValid;
};

const requestMddlwr = store => next => action => {
    const { dispatch, getState } = store;

    if (!matchPreConditions(getState, action)) {
        return next(action);
    }

    const { types = [], callApi, onSuccess, onFailure } = action;
    const [requestType, successType, errorType] = types;

    dispatch({ type: requestType });

    return callApi()
        .then(response => {
            dispatch({
                type: successType,
                payload: response,
                states: getState(),
            });

            if (isFunction(onSuccess)) {
                onSuccess(response, dispatch, getState);
            }
        })
        .catch(error => {
            let transportError = error;
            if ( (error.response.status === 500 && error.response.data.message === 'jwt expired') || 
                 (error.response.status === 401 && error.response.data.message === 'Missing Token')
            ){
                const message = translateString('messages.tokenExpired');
                transportError = { message: message };
            }
            
            dispatch(sendErrorMessage(transportError));

            dispatch({
                type: errorType,
                transportError,
            });

            if (isFunction(onFailure)) {
                onFailure(transportError, dispatch, getState);
            }
        });
};

// eslint-disable-next-line no-restricted-exports
export { requestMddlwr as default };
