// import React, { useEffect, useState } from 'react';
import React, { useState } from 'react';

import SimpleModalRemoteControlled from '../../../common/SimpleModalRemoteControlled';
import ModalActionButton from '../../../common/ModalActionButton';
import ModalStartButton from '../../../common/ModalStartButton';
import {  Form, Icon, Segment } from 'semantic-ui-react';
import UserForm from './UserForm';
import * as userModel from '../../../../models/user';
import { objectHasEmptyValues, isValidMailAddress } from '../../../../misc/utils';
// import { disabledUser } from './DisabledUser';
import { translateString } from '../../../../i18n/utils';
import RightsTable from './RightsTable';

export default function UserAndRightsModal({ 
    disabled, 
    icon, 
    text, 
    buttonText, 
    submitCallback,
    cancelCallback,
    user = userModel.user,
    authUser
}) {
    const [_user, setUser] = useState(user);
    const [isValidForm, setIsValidForm] = useState(true);
    const [showUserModal, setShowUserModal] = useState(false);
    const [showRightsModal, setShowRightsModal] = useState(false);

    const saveUserEvent = (userData=_user) => {
        if (objectHasEmptyValues(userData) || !isValidMailAddress(userData.mail)) {
            setIsValidForm(false);
        } else {
            setIsValidForm(true);
            setShowUserModal(false);
            setShowRightsModal(true);
            // Wirklich gespeichert wird erst auf der zweiten Seite bei saveRightsEvent!
        }
    };
    const saveRightsEvent = (userData = _user)=>{
        setShowUserModal(false);
        setShowRightsModal(false);
        submitCallback(userData);
    };

    const cancelRightsEvent = ()=>{
        setShowUserModal(true);
        setShowRightsModal(false);
    };

    const cancelUserEvent = ()=>{
        setIsValidForm(true);
        setShowUserModal(false);
        setShowRightsModal(false);
        setUser(user); // User Eingabe wieder verwerfen
        cancelCallback(_user);
    };

    const rightsHeaderText = translateString('user.userRights', {
        username: _user.mail,
    });

    return (
        <>
            { !showRightsModal &&

                <SimpleModalRemoteControlled
                    button={(props) => <ModalStartButton buttonIcon={icon} buttonText={buttonText} disabled={disabled} onOpen={()=>setShowUserModal(true)}/>}
                    isOpen={showUserModal}
                    openCallback={()=>setShowUserModal(true)}
                    closeCallback={()=>setShowUserModal(false)}
                    header={
                        <div>
                            <Icon name={icon} />
                            {text}
                        </div>
                    }
                    content={
                        <div>
                            <Segment basic vertical>
                            <UserForm
                                onChange={(key, val) => {
                                    setUser({ ..._user, [key]: val });
                                }}
                                user={_user}
                                isValidForm={isValidForm}
                                setIsValidForm={setIsValidForm}
                            />
                            </Segment>
                            <Form.Group widths='equal' style={{ float: 'right' }}>
                                <div>
                                    <Icon.Group>
                                        <Icon name='asterisk' color='red' size='tiny' corner='top right' />
                                    </Icon.Group>
                                    {translateString('common.mandatory')}
                                </div>
                            </Form.Group>
                        </div>
                    }
                    actions={(props) => <ModalActionButton submitCallback={saveUserEvent} cancelCallback={cancelUserEvent} isValidForm={isValidForm} submitIcon='arrow right'
                    submitText='common.next' />}
                />
            }  
            { showRightsModal &&
                
                <SimpleModalRemoteControlled
                    button={(props) => <ModalStartButton buttonIcon='unlock' buttonText={buttonText} disabled={disabled} onOpen={()=>setShowRightsModal(true)}/>}
                    isOpen={showRightsModal}
                    openCallback={()=>setShowRightsModal(true)}
                    closeCallback={()=>setShowRightsModal(false)}
                    header={
                        <div>
                            <Icon name='unlock' />
                            {rightsHeaderText}
                        </div>
                    }
                    content={
                        <RightsTable
                            user={_user}
                            authUser={authUser}
                            callback={(key, right, val) => setUser({ ..._user, [key]: { ..._user[key], [right]: val } })}
                        />
                    }
                    actions={(props) => <ModalActionButton submitCallback={saveRightsEvent} cancelCallback={cancelRightsEvent} isValidForm={isValidForm} />}
                />

            }
        </>
    );
}
