import React from 'react';

import { translateString } from '../../i18n/utils';

/**
 * Input Komponente mit Icon
 *
 * @export
 * @param {*} {
 * 	disabled = false,
 * 	placeholder = translateString('placeholder.input'),
 * 	changeCallback,
 * 	icon,
 * 	type = 'text',
 * 	autoFocus = false,
 * 	handleKeyPress,
 * 	value,
 * }
 * @returns
 */
export default function IconInput({
    disabled = false,
    placeholder = translateString('common.placeholder'),
    changeCallback,
    icon,
    type = 'text',
    autoFocus = false,
    handleKeyPress,
    value,
}) {
    return (
        <div className='ui left icon fluid input'>
            <input
                type={type}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                onKeyPress={handleKeyPress}
                onChange={e => changeCallback(e.target.value)}
                value={value}
            />
            <i aria-hidden='true' className={`${icon} icon`} />
        </div>
    );
}
