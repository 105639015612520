// @flow
import React from 'react';
import { Form } from 'semantic-ui-react';
import MapsLayerElement from './MapsLayerElement';
import { isEmpty } from '../../../../misc/utils';
import Loading from '../../../common/Loading';
export default function MapsLayers({  layers, selLayers, loader, callback }) {
    
    if(isEmpty(layers)) return <Loading show={loader} />;
    if (layers?.length > 0 ){
        return (
            <Form.Group>
                <div style={{width:'100%', marginLeft: '1.5em'}}>
                <Loading show={loader} />
                {layers.map(layerElement => {
                    const checked = selLayers?.includes(layerElement.name);
                    return <MapsLayerElement layerElement={layerElement} key={layerElement.name} isChecked={checked} callback={callback} />
                })}
                </div>
            </Form.Group>
        );
    };

    return;
}
