// import React, {useState} from 'react';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import IconButton from './IconButton';

import { isEmpty, isFunction } from '../../misc/utils';

const ModalStartButton = ({ 
    buttonIcon, 
    buttonStyle, 
    buttonText, 
    disabled, 
    onClick,  
    onOpen,
    open = false }) => {

        if(open){
            onOpen();
        }
        if (isEmpty(buttonText)) {
            return (
                <IconButton
                    onClick={() => {
                        if (isFunction(onClick)) {
                            onClick();
                        }
                        onOpen();
                    }}
                    icon={buttonIcon}
                    disabled={disabled}
                    style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, ...buttonStyle }}
                />
            );
        }

        return (
            <Button
                icon
                style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, ...buttonStyle }}
                labelPosition='left'
                onClick={() => {
                    if (isFunction(onClick)) {
                        onClick();
                    }
                    onOpen();
                }}
                disabled={disabled}
            >
                <Icon name={buttonIcon} />
                {buttonText}
            </Button>
        );
};

export default ModalStartButton;