/* eslint-disable import/prefer-default-export */
import * as appActions from './appActions';
import * as domainActions from './domainActions/commonActions';
import * as operatorActions from './domainActions/operatorActions';
import * as deviceActions from './domainActions/deviceActions';
import * as userActions from './domainActions/userActions';
import * as licenseActions from './domainActions/licenseActions';
import * as authActions from './authActions';
import * as mapsActions from './domainActions/mapsActions';

export const actions = {
    ...appActions,
    ...domainActions,
    ...operatorActions,
    ...deviceActions,
    ...userActions,
    ...licenseActions,
    ...authActions,
    ...mapsActions,
};
