import React from 'react';
import { Table } from 'semantic-ui-react';

import UserModal from './UserModal';
import RightsModal from './RightsModal';
import DeleteConfirmDialog from '../../../common/DeleteConfirmDialog';
import { disabledUser } from './DisabledUser';
import { translateComp, translateString } from '../../../../i18n/utils';

export default function UserTableRow({ rights, user, submitUser, deleteCallback, authUser }) {
    const userHasRigths =
        user.user.create ||
        user.user.read ||
        user.user.update ||
        user.user.delete ||
        user.operator.create ||
        user.operator.read ||
        user.operator.update ||
        user.operator.delete ||
        user.device.create ||
        user.device.read ||
        user.device.update ||
        user.device.delete;
    const ButtonAlertStyle = userHasRigths ? { border: '1px solid green' } : { border: '1px solid red' };

    const deleteCallbackHandler = () => {
        deleteCallback(user);
    };
    const deleteMessage = translateString('messages.deleteUserMessage', {
        forname: user.forname,
        surname: user.surname,
        mail: user.mail,
    });

    const userItsellf = authUser.mail === user.mail;
    const noRightsChangesAllowed = !rights.update || userItsellf;

    const authUsersRights = noRightsChangesAllowed ? disabledUser : authUser;

    return (
        <Table.Row>
            <Table.Cell>{user.mail}</Table.Cell>
            <Table.Cell>{user.forname}</Table.Cell>
            <Table.Cell>{user.surname}</Table.Cell>
            <Table.Cell>{user.phone}</Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <UserModal
                    disabled={!rights.update || authUser.mail === user.mail}
                    icon='pencil'
                    text={translateComp('common.user', 'User')}
                    submitCallback={submitUser}
                    user={user}
                    authUser={authUser}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <DeleteConfirmDialog
                    confirmCallback={deleteCallbackHandler}
                    header={translateString('user.delete')}
                    content={deleteMessage}
                    cancelButtonText={translateString('common.abort')}
                    confirmButtonText={translateString('common.ok')}
                    disabled={!rights.delete || authUser.mail === user.mail}
                />
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <RightsModal 
                    submitCallback={submitUser} 
                    user={user} 
                    submitDisabled={!rights.update || authUser.mail === user.mail}
                    authUser={authUsersRights} 
                    buttonStyle={ButtonAlertStyle} 
                />
            </Table.Cell>
        </Table.Row>
    );
}
