/* eslint-disable max-len */
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';

i18n.use(detector)
    .use(backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'de', // use en if detected lng is not available
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        // Namespaces are a feature in i18next internationalization framework which allows you to separate translations that get loaded into multiple files
        ns: ['translations'],
        defaultNS: 'translations',

        // react i18next special options (optional)
        react: {
            wait: false, // assert all provided namespaces are loaded before rendering the component
            withRef: false,
            bindI18n: 'languageChange loaded', // which events trigger a rerender
            bindStore: 'added removed', // which events on store trigger a rerender
            nsMode: 'default', // namespaces will be loaded an the first will be set as default
        },
    });

export default i18n;
