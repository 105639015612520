import React from 'react';
import OperatorDeviceInspector from './OperatorDeviceInspector';

/**
 * Klasse fuer die Hauptansicht des Dashboards
 * @export
 * @param {*} props
 * @returns
 */
export default function DashboardMainModule(props) {
    return <OperatorDeviceInspector {...props} />;
}
