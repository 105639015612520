import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { isFunction } from '../../misc/utils';

/**
 * Gibt einen Button mit einem entsprechenden Icon zurueck
 * @export
 * @param {*} { onClick, icon, text, disabled = false, style }
 * @returns
 */
export default function IconButton({ onClick, icon, text, disabled = false, style }) {
    return (
        <Button
            icon
            style={style}
            labelPosition={text ? 'left' : null}
            onClick={() => {
                if (isFunction(onClick)) {
                    onClick();
                }
            }}
            disabled={disabled}
        >
            <Icon name={icon} />
            {text}
        </Button>
    );
}
