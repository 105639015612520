import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react';

import '../../css/login.css';

import AuthCard from './AuthCard';
import IconInput from '../common/IconInput';
import SecureInput from '../common/SecureInput';
import AnimatedIconButton from '../common/AnimatedIconButton';

import { VALIDATION_STATE } from '../../misc/utils';

/**
 * Komponente fuer die Anmeldung
 * @type {Login}
 */
export default class Login extends Component<Props, State> {
    /**
     * Erzeugt die Komponente
     * @param {Object} props Die Properties der Komponente
     */
    constructor(props: Object) {
        super(props);

        this.state = {
            username: '',
            password: '',
            isValid: VALIDATION_STATE.NONE,
        };
    }

    /**
     * Ruft den Callback auf wenn die Anmeldung betaetigt wird
     */
    onLogin = (): void => {
        const { callback } = this.props;
        const { username, password } = this.state;

        const isValid = username.trim() !== '' && password.trim() !== '';

        if (isValid) {
            callback(username, password);
        } else {
            this.setState({
                isValid: VALIDATION_STATE.ERROR,
            });
        }
    };

    /**
     * Workaround fuer Submit Probleme im Firefox
     * @param {Event} e Das Event das ausgeloest wurde
     */
    handleKeyPress = (e: SyntheticEvent<>): void => {
        const { loader } = this.props;

        if (e.key === 'Enter' && !loader) {
            this.onLogin();
        }
    };

    /**
     * Rendert die Komponente
     * @return {JSX} JSX Markup
     */
    render() {
        const { loader, title, username, password, button } = this.props;
        const { isValid } = this.state;

        return (
            <AuthCard
                title={title}
                buttons={
                    <div className='authButtons'>
                        <Grid columns='equal'>
                            <Grid.Column>
                                <AnimatedIconButton onClick={this.onLogin} icon='sign in' text={button} />
                            </Grid.Column>
                        </Grid>
                    </div>
                }
            >
                <Form onSubmit={this.onLogin}>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <IconInput
                                    validationState={isValid}
                                    disabled={loader}
                                    placeholder={username}
                                    changeCallback={val => this.setState({ username: val, isValid: VALIDATION_STATE.NONE })}
                                    icon='mail'
                                    handleKeyPress={this.handleKeyPress}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <SecureInput
                                    validationState={isValid}
                                    disabled={loader}
                                    placeholder={password}
                                    changeCallback={val => this.setState({ password: val, isValid: VALIDATION_STATE.NONE })}
                                    type='password'
                                    handleKeyPress={this.handleKeyPress}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </AuthCard>
        );
    }
}
